import PropTypes from 'prop-types';
import { Button } from '../../_atoms/Button';
import { Image } from '../../_atoms/Image';

export const ImageButton = (props) => {
  return (
    <Button 
      type={props.type} 
      act={props.act}
      name={props.name} 
      onClick={props.onClick} 
      disabled={props.disabled} 
      className={props.className} 
      onMouseOver={props.onMouseOver}
    >
      {props.label}
      <Image 
        type={props.type} 
        src={props.src} 
        alt={props.alt} 
        act={props.act}
        />
      {props.children}
    </Button>
  );
};

ImageButton.propTypes = {
  type : PropTypes.string,
  src : PropTypes.string.isRequired,
  alt : PropTypes.string.isRequired,
  act : PropTypes.bool
};

ImageButton.defaultProps = {
  type : "mainBook",
  src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book1.png',
  alt : 'Phonics Hero Book1',
  act : true
};
