import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setVideoPlay } from '../../../store/VideoSlice';
import { Button } from '../../_atoms/Button';
import { Progress } from '../../_atoms/Progress/Index';

const PlayBarWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  grid-column-gap: 1.5625vw;
  width: 100%;
  height: 6.25vw;
  background: #333 url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/chant_progress_bg.png) center left / cover no-repeat;
`;

const TimeWrap = styled.div`
  font-size: 2.083333vw;
  font-weight: 800;
  color: #fff;
  margin-right: 1.5625vw;
`;

const Time = styled.span`
  font-size: 2.083333vw;
  font-weight: 800;
  color: #fff;
`;

export const VideoPlayBar = () => {
  const state = useSelector((state) => state.video);
  const dispatch = useDispatch();

  return (
    <>
      <PlayBarWrap>
        <Button type="videoPlay" act={state.play} onClick={() => dispatch(setVideoPlay(!state.play))} />
        <Progress name="video" />
        <TimeWrap>
          <Time>{state.current_time}</Time>&nbsp;/&nbsp;<Time>{state.duration_time}</Time>
        </TimeWrap>
      </PlayBarWrap>
    </>
  );
};