import React from 'react';
import { Main } from '../../_atoms/Main';
import { Wrap } from '../../_atoms/Wrap';

export const Frame = (props) => {
  return (
    <Wrap>
      <Main type={props.type}>{props.children}</Main>
    </Wrap>
  );
};

Frame.propTypes = {

};

Frame.defaultProps = {

};