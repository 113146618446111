import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mic : false,
  info : false,
  pop : false,
  num : 0,
  play : false,
  record : false,
  record_effect : false,
  record_play : false,
  score : "",
  score_name : "",
  score_word : null,
};

const speaking = createSlice({
  name : "speaking",
  initialState,
  reducers : {
    resetSpeaking : () => initialState,
    resetRecord(state, action) {
      state.mic = false;
      state.info = false;
      state.pop = false;
      state.play = false;
      state.record = false;
      state.record_effect = false;
      state.record_play = false;
      state.score = "";
      state.score_name = "";
      state.score_word = [];
    },
    setMic(state, action) {
      state.mic = action.payload;
    },
    setInfo(state, action) {
      state.info = action.payload;
    },
    setSpeakingPop(state, action) {
      state.pop = action.payload;
    },
    setNum(state, action) {
      state.num = action.payload;
    },
    setReadersPlay(state, action) {
      state.play = action.payload;
    },
    setRecord(state, action) {
      state.record = action.payload;
    },
    setRecordEffect(state, action) {
      state.record_effect = action.payload;
    },
    setRecordPlay(state, action) {
      state.record_play = action.payload;
    },
    setScore(state, action) {
      state.score = action.payload;
    },
    setScoreName(state, action) {
      state.score_name = action.payload;
    },
    setScoreWord(state, action) {
      state.score_word = action.payload;
    },
  }
});

export const {
  resetSpeaking,
  resetRecord,
  setMic,
  setInfo,
  setSpeakingPop,
  setNum,
  setReadersPlay,
  setRecord,
  setRecordEffect,
  setRecordPlay,
  setScore,
  setScoreName,
  setScoreWord,
} = speaking.actions;

export default speaking;