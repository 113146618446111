import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setCurrentUnit, setPop, setType } from '../../../store/BookSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { ImageButton } from '../../_molecules/ImageButton';

const UnitWrap = styled.div`
  display: grid;
  grid-template: ${(props) => props.book === 3 ? 'repeat(2, auto) / repeat(6, auto)' : 'repeat(3, auto) / repeat(4, auto)'};
  width: max-content;
  grid-gap: 1.041667vw 1.302083vw;
  margin: ${(props) => props.book === 3 ? '7.71875vw auto' : '1.71875vw auto'};
`;  

const Readers = [
  [4, 8, 12],
  [4, 8, 12],
  [6, 12]
];

export const ContentsUnitWrap = (props) => {
  const state = useSelector((state) => state.book);
  const dispatch = useDispatch();

  return (
    <UnitWrap book={state.current_book}>
      {props.card && props.card.map((unit, idx) => {
        return (
          <ImageButton
            src={'https://content-cdn.chungchy.com/Phonics_Hero/book' + state.current_book + '/unit/unitlist_book' + state.current_book + '_unit' + (idx + 1) + '.png'}
            alt='unit1'
            type='unit'
            onClick={() => {
              dispatch(setBtnSound(true));
              dispatch(setCurrentUnit(idx));
              dispatch(setPop(true));
              dispatch(setType(Readers[state.current_book - 1].indexOf(idx + 1) < 0 ? 'unit' : 'readers'));
            }}
          />
        )
      })}
    </UnitWrap>
  );
};