import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const InputTag = styled.input`
  ${(props) => {
    switch (props.name) {
      case "audio": case "video":
        return css`
          width: ${props.name === 'audio' ? '41.666667vw' : '100%'};
          ${props.name === 'video' && 'flex : auto;'}
          height: 1.09375vw;
          border-radius: 0.546875vw;

          background: ${(props) => css`linear-gradient(to right, rgb(84, 147, 252) ${isNaN(props.value) ? 0 : props.value}%, rgb(213, 213, 213) ${isNaN(props.value) ? 0 : props.value}%)`};

          &::-webkit-slider-runnable-track {
            width: 41.666667vw;
            height: 1.09375vw;
            border-radius: 0.546875vw;
          }

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            transform: translateY(calc(-50% + 1.09375vw * 0.5));
            width: 3.75vw;
            height: 3.854167vw;
            background: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_progressbar_ball.png) center / contain no-repeat;
            filter: drop-shadow(0 .104167vw .15625vw rgba(0, 0, 0, 0.4));

            width: 3.125vw;
            height: 3.125vw;
          }
        `
      case "auth": 
        return css`
          outline: 1px solid #ccc;
          padding: 1vw 1.4vw;
          font-size: 2vw;
          border-radius: 0.8vw;
          margin-top: 2.083333vw;

          &::placeholder {
            color: #ccc;
          }

          &:focus {
            outline: 1px solid #005fff;
          }
        `
      default:
    }
  }}
`;

export const Input = (props) => {
  return (
    <>
      <InputTag 
        type={props.type}
        id={props.id}
        max={props.max}
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        placeholder={props.placeholder}
      />
    </>
  );
};

Input.propTypes = {
  type : PropTypes.string,
  id : PropTypes.string,
  max : PropTypes.number,
  name : PropTypes.string,
  checked : PropTypes.bool,
};

Input.defaultProps = {
  type : 'checkbox',
  id : 'sound',
  checked : true,
};