import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  play : true,
  type : "",
  control : true,
  duration_time : '00:00',
  current_time : '00:00',
  current_range : 0,
  change_range : 0,
};

const video = createSlice({
  name : "video",
  initialState,
  reducers : {
    resetVideo : () => initialState,
    setVideoPlay(state, action) {
      state.play = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setControl(state, action) {
      state.control = action.payload;
    },
    setVideoDurationTime(state, action) {
      let minute = Math.floor(action.payload / 60);
      minute = minute < 10 ? "0" + minute.toString() : minute;
      
      let second = parseInt(action.payload - minute * 60);
      second = second < 10 ? "0" + second.toString() : second;

      state.duration_time = minute + ":" + second;
    },
    setVideoCurrentTime(state, action) {
      let minute = Math.floor(action.payload / 60);
      minute = minute < 10 ? "0" + minute.toString() : minute;
      
      let second = parseInt(action.payload - minute * 60);
      second = second < 10 ? "0" + second.toString() : second;

      state.current_time = minute + ":" + second;
    },
    setVideoCurrentRange(state, action) {
      state.current_range = action.payload;
    },
    setVideoChangeRange(state, action) {
      state.change_range = action.payload;
    },
  }
});

export const {
  resetVideo,
  setVideoPlay,
  setType,
  setControl,
  setVideoDurationTime,
  setVideoCurrentTime,
  setVideoCurrentRange,
  setVideoChangeRange
} = video.actions;

export default video;