import { PropTypes } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { resetCard } from '../../../store/CardSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { Div } from '../../_atoms/Div';
import { Image } from '../../_atoms/Image';
import { Frame } from '../../_molecules/Frame';
import { ImageButton } from '../../_molecules/ImageButton';
import { FlashCardWrap } from '../../_organisms/FlashCardWrap';

export const FlashCardTemplate = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <Frame type="flashCard">
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_home_150px.png'
        alt='home'
        type='home'
        onClick={() => {
          dispatch(setBtnSound(true));
          dispatch(resetCard());
          navigate("/");
        }}
      />
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_pre_150px.png'
        alt='back'
        type='back'
        onClick={() => {
          dispatch(setBtnSound(true));
          dispatch(resetCard());
          navigate("/book/contents");
        }}
      />
      <Div type="title">
        <Image
          src={'https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_title_'+props.type+'.png'}
          alt={'Flash Card ('+props.type+')'}
          type='title'
        />
      </Div>
      <FlashCardWrap type={props.type} />
    </Frame>
  );
};

FlashCardTemplate.propTypes = {
  type : PropTypes.string
};

FlashCardTemplate.defaultProps = {
  type : 'sound'
};