import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const LabelTag = styled.label`
  ${(props) => {
    switch (props.for) {
      case "sound" :
        return css`
          position: relative;
          display: flex;
          width: 8.958333vw;
          height: 4.010417vw;
          cursor: pointer;
        `
      default:
    }
  }}
`;

export const Label = (props) => {
  return (
    <LabelTag for={props.for}>{props.children}</LabelTag>
  );
};

Label.propTypes = {
  for : PropTypes.string,
};

Label.defaultProps = {
  for : 'sound'
};