import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Readers } from '../../../asset/utils/Readers';
import { setNum, setReadersPlay } from '../../../store/SpeakingSlice';
import { Image } from '../../_atoms/Image';
import { ImageButton } from '../../_molecules/ImageButton';

const StyledSlider = styled(Slider)`
  width : 68.2vw;
  height : auto;
  margin : auto;

  .slick-list {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    width : 100%;
    height : 100%;
  }

  .slick-prev.slick-disabled {
    display: none;
  }
  
  .slick-next.slick-disabled {
    display: none;
  }
`;

export const SpeakingSlideWrap = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  
  const slider = useRef();

  const settings = {
    dots : false,
    infinite : false,
    speed : 500,
    fade: true,
    slideToScroll : 0,
    prevArrow : <ImageButton
                  type="readersPre"
                  src="https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-prev.png"
                  alt="readers prev"
                  onClick={{  }}
                />,
    nextArrow : <ImageButton
                  type="readersNext"
                  src="https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-next.png"
                  alt="readers next"
                />,
    beforeChange : (num, next) => {
      state.speaking.play && dispatch(setReadersPlay(false));
      dispatch(setNum(next));
    },
  };

  return (
    <StyledSlider {...settings} ref={slider}>
      {Readers[state.book.current_book - 1] && Readers[state.book.current_book - 1][state.book.current_readers].sentence.map((readers, idx) => {
        return <Image 
                type='readersBook'
                src={"https://content-cdn.chungchy.com/Phonics_Hero/book"+state.book.current_book+"/flashCard/"+(state.book.current_readers + 1)+"_"+(idx + 1)+".png"}
                alt={"book"+state.book.current_book+" readers "+(state.book.current_readers + 1)+"-"+(idx + 1)}
              />
      })}
    </StyledSlider>
  );
};