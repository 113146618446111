import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import styled from 'styled-components';

const AudioTag = styled(H5AudioPlayer)`
  width: 0;
  height: 0;
  margin: 0;
  display: none;
`;

export const Audio = forwardRef((props, player) => {
  return (
    <AudioTag
      ref={player}
      src={props.src}
      preload="true"
      autoPlay={props.autoPlay}
    />
  );
});

Audio.propTypes = {
  src : PropTypes.string.isRequired,
};

Audio.defaultType = {
  src : "https://content-cdn.chungchy.com/Phonics_Hero/mp3/homebgm_Bike_Rides.mp3",
};