import { Outlet, useLocation } from "react-router-dom";
import CheckToken from "../auth/CheckToken";

export const PrivateRoute = () => {
  const location   = useLocation();
  const { isAuth } = CheckToken(location.key);

  if (isAuth === "Failed") {
    alert("Unable to find login information");
    window.self.close();
  }

  return <Outlet />;
};
