const TIME_OUT = 300 * 1000;

const statusError = {
  status : false,
  json : {
    error: ["The connection is not smooth. Please try again in a moment."]
  }
};

const requestPromise = (url, option) => {
  return fetch (url, option);
};

const timeoutPromise = () => {
  return new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), TIME_OUT));
};

const getPromise = async (url, option) => {
  return await Promise.race([
    requestPromise(url, option),
    timeoutPromise()
  ]);
};

// access token 재발급
export const requestToken =  async ({refresh_token, access_token}) => {
  const option = {
    method : 'POST',
    headers : {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body : JSON.stringify({
      player: {
        TYPE: "ACCESS_REISSUE",
        ACCESS_TOKEN : access_token,
        REFRESH_TOKEN : refresh_token
      },
    })
  };

  const data = await getPromise('https://www.phonics-hero.com/api/api_auth', option).catch(() => {
    return statusError;
  });

  if (data.status === 200) {
    const status = data.ok;
    const code = data.status;
    const text = await data.text();
    const json = text.length ? JSON.parse(text) : "";

    return {
      status,
      code,
      json
    };
  } else {
    return statusError;
  }
};