import { PropTypes } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlashSound } from '../../../asset/utils/FlashSound';
import { FlashWord } from '../../../asset/utils/FlashWord';
import { FlashCard } from '../../_molecules/FlashCard';
import { FlashCardWord } from '../../_molecules/FlashCardWord';

const FlashCardBox = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row-gap: 2.083333vw;
`;

const BtnWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 1.197917vw;
`;

export const FlashCardWrap = (props) => {
  const state = useSelector((state) => state.book);
  return (
    <FlashCardBox>
      <FlashCard type={props.type} card={props.type === 'sound' ? FlashSound[state.current_book - 1] : FlashWord[state.current_book - 1]} />
      {props.type === 'sound' ? 
        <br/> 
        : (
          FlashWord[state.current_book - 1][state.current_unit].button.map((card, idx) => {
            if (idx === 0 || (FlashWord[state.current_book - 1][state.current_unit].button.length > 9 && idx === Math.ceil(FlashWord[state.current_book - 1][state.current_unit].button.length / 2))) {
              return <BtnWrap>
                      <FlashCardWord 
                        type={props.type} 
                        card={FlashWord[state.current_book - 1][state.current_unit]} 
                        start={idx} 
                        end={idx === 0 ? 
                              (FlashWord[state.current_book - 1][state.current_unit].button.length > 9 ? Math.ceil(FlashWord[state.current_book - 1][state.current_unit].button.length / 2) - 1 : FlashWord[state.current_book - 1][state.current_unit].button.length - 1) 
                              : FlashWord[state.current_book - 1][state.current_unit].button.length - 1}
                      />
                    </BtnWrap>
            }
          })
        )
      }
    </FlashCardBox>
  );
};

FlashCardWrap.propTypes = {
  type : PropTypes.string,
};

FlashCardWrap.defaultProps = {
  type : 'sound'
};