import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const H1 = styled.h1`
  ${(props) => {
    switch (props.type) {
      case "popup":
        return css`
          display: flex;
          align-items: center;
          justify-content: center;
          grid-column-gap: 2.083333vw;
          height: 7.291667vw;
          font-size: 3.125vw;
          font-weight: 800;
        `;
      default:
    }
  }}
`;

export const Headline = (props) => {
  return (
    <>
      {props.size === 1 && <H1 type={props.type}>{props.children}</H1>}
      {props.size === 2 && <h2 type={props.type}>{props.children}</h2>}
      {props.size === 3 && <h3 type={props.type}>{props.children}</h3>}
      {props.size === 4 && <h4 type={props.type}>{props.children}</h4>}
      {props.size === 5 && <h5 type={props.type}>{props.children}</h5>}
      {props.size === 6 && <h6 type={props.type}>{props.children}</h6>}
    </>
  );
};

Headline.propTypes = {
  size : PropTypes.number,
  type : PropTypes.string
};

Headline.defaultProps = {
  size : 1,
  type : 'popup'
};