import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth : false,
  book : null,
  code : null,
  result : false,
  check : false,
};

const bookAuth = createSlice({
  name : "bookAuth",
  initialState,
  reducers : {
    resetBookAuth : () => initialState,
    setAuth : (state, action) => {
      state.auth = action.payload;
    },
    setBook : (state, action) => {
      state.book = action.payload;
    },
    setCode : (state, action) => {
      state.code = action.payload;
    },
    setResult : (state, action) => {
      state.result = action.payload;
    },
    setCheck : (state, action) => {
      state.check = action.payload;
    }
  }
});

export const {resetBookAuth, setAuth, setBook, setCode, setResult, setCheck} = bookAuth.actions;

export default bookAuth;