import { PropTypes } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const DivTag = styled.div`
  ${(props) => {
    switch (props.type) {
      case "book":
        return css`
          box-sizing: content-box;
          padding-top: 2.5vw;
          height: 6.197917vw;
          background: center bottom /auto 6.197917vw no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/unitlist_book${props.book}_title.png);
        `;
      case "cover":
        return css`
          grid-row: 1 / span 3;
          width: 19.651416vw;
          height: auto;
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book${props.book}.png);
        `;
      case "title":
        return css`
          box-sizing: content-box;
          padding-top: 2.5vw;
          height: 6.197917vw;
          text-align: center;
        `
      case "speaking":
        return css`
          background-image: none;
          height: 13.541667vw;
          padding-top: 0;
        `
      default:
    }
  }}
`;

export const Div = (props) => {
  const state = useSelector((state) => state.book);
  
  return (
    <DivTag type={props.type} book={state.current_book}>{props.children}</DivTag>
  );
};

Div.propTypes = {
  type : PropTypes.string,
  book : PropTypes.number
};

Div.defaultProps = {
  type : 'book',
  book : 1
};