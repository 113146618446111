import React, { useEffect, useRef } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setAudioCurrentRange, setAudioCurrentTime, setAudioDurationTime, setAudioPlay, setRepeat, setRewind } from '../../../store/AudioSlice';
import { Button } from '../../_atoms/Button';
import { Progress } from '../../_atoms/Progress/Index';

const BarWrap = styled.div`
  align-self: flex-end;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 69.270833vw;
  height: 7.291667vw;
  background: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_progress_bg.png) center /contain no-repeat;
`;

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

const TimeWrap = styled.div`
  font-size: 2.083333vw;
  font-weight: 800;
  color: #5493fc;
  text-align: center; width: 13vw;
`;

const Time = styled.span`
  font-size: 2.083333vw;
  font-weight: 600;
  color: #5493fc;
`;

export const PlayBar = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const player = useRef();

  useEffect(() => {
    // mp3 렌더링시 자동재생 되는 것 막기
    player.current.audio.current.pause();

    // 재생 / 일시정지
    state.audio.play ? player.current.audio.current.play() : player.current.audio.current.pause();

    player.current.audio.current.loop = state.audio.repeat ? true : false;
  }, [state.audio.current_unit, state.audio.current_track, state.audio.play, state.audio.repeat]);

  useEffect(() => {
    player.current && player.current.audio.current.addEventListener('timeupdate', onTimeUpdate);
  });

  const onTimeUpdate = () => {
    // 현재 재생 시간
    player.current && player.current.audio.current && dispatch(setAudioCurrentTime(player.current.audio.current.currentTime));

    // progress bar
    player.current && dispatch(setAudioCurrentRange((100 * parseInt(player.current.audio.current.currentTime)) / parseInt(player.current.audio.current.duration)));
  };

  const onTrackPlayEnd = () => {
    dispatch(setAudioPlay(false));
    dispatch(setRewind(true));
    dispatch(setAudioCurrentTime(0));

    // progress bar
    dispatch(setAudioCurrentRange(0));
  };
  
  return (
    <BarWrap>
      <Button 
        type="repeat" 
        act={state.audio.repeat}
        onClick={() => dispatch(setRepeat(!state.audio.repeat))}
      />
      <AudioPlayer
        ref={player}
        src={'https://content-cdn.chungchy.com/Phonics_Hero/book' + state.book.current_book + '/flashCard/mp3/unit_01/' + (state.audio.play_unit + 1) + '_' + (state.audio.current_track + 1) + '.mp3'}
        autoPlayAfterSrcChange={state.audio.play}
        showSkipControls={true}
        showJumpControls={false}
        hasDefaultKeyBindings={false}
        preload="true"
        onEnded={onTrackPlayEnd}
        onLoadedMetaData={() => {
          dispatch(setAudioDurationTime(player.current.audio.current.duration));
        }}
      />
      <Progress name="audio" range={state.audio.current_range} player={player.current}/>
      <TimeWrap>
        <Time>{state.audio.current_time}</Time>&nbsp;/&nbsp;<Time>{state.audio.duration_time}</Time>
      </TimeWrap>
    </BarWrap>
  );
};