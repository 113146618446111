import React, { useRef } from 'react';
import { Video } from '../../_atoms/Video';
import { Frame } from '../../_molecules/Frame';
import { VideoControlWrap } from '../../_organisms/VideoControlWrap';

export const VideoTemplate = () => {
  const player = useRef();

  return (
    <Frame>
      <Video ref={player} />
      <VideoControlWrap />
    </Frame>
  );
};