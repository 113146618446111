import React, { useEffect, useRef, useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setPlay } from '../../../store/CardSlice';
import { Button } from '../../_atoms/Button';

const CardWrap = styled.div`
  display: flex;
  justify-content: center;
  grid-column-gap: 1.041667vw;
`;

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const FlashCard = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const player = useRef();
  
  const [act, setAct] = useState(false);
  const [choice, setChoice] = useState(null);
  const [back, setBack] = useState([]);
  const [mp3, setMp3] = useState(null);

  useEffect(() => {
    if (props.type === 'word') {
      let array = [];
  
      props.card && props.card[state.book.current_unit][state.card.current_word].map(() => {
        array.push(false);
      });
  
      setBack(array);
    }
  }, [state.card.current_word]);

  return (
    <>
      <CardWrap>
        {(props.type === 'sound' ? props.card && props.card[state.book.current_unit].card_front.map((card, idx) => {
          return (
            <Button
              type="card"
              name={props.type}
              act={act}
              src={card.img}
              className={act ? (idx === choice ? 'ani1 ani2' : 'ani3 ani4') : (back.length > 0 && idx === choice ? 'ani1' : 'ani3')}
              onClick={() => {
                if (state.card.play) {
                  return false;
                } else {
                  setAct(true);
                  setChoice(idx);
                  setMp3(card.sound);
                  dispatch(setPlay(true));
                }

              }}
            />
          )
        }) : props.card && props.card[state.book.current_unit][state.card.current_word].map((card, idx) => {
          return (
            <Button
              type="card"
              name={props.type}
              act={act}
              src={
                props.card[state.book.current_unit].button.length <= 3 ? 
                (back[idx] ?
                  'book' +state.book.current_book + '/flashCard/B' + state.book.current_book + "-Unit" + (state.book.current_unit + 1) + "-w-" + card 
                  : 'book' +state.book.current_book + '/flashCard/B' + state.book.current_book + "-Unit" + (state.book.current_unit + 1) + "-" + card)
                : (back[idx] ?
                  'book' +state.book.current_book + '/flashCard/B' + state.book.current_book + "-Unit" + (props.card[state.book.current_unit].unitNum[props.card[state.book.current_unit].button.indexOf(state.card.current_word)]) + "-w-" + card 
                  : 'book' +state.book.current_book + '/flashCard/B' + state.book.current_book + "-Unit" + (props.card[state.book.current_unit].unitNum[props.card[state.book.current_unit].button.indexOf(state.card.current_word)]) + "-" + card)
              }
              className={act ? (idx === choice ? 'ani1 ani2' : 'ani3 ani4') : (idx === choice ? 'ani1' : 'ani3')}
              onClick={() => {
                if (state.card.play) {
                  return false;
                } else {
                  setAct(true);
                  setChoice(idx);
                  setMp3("book" + state.book.current_book + '/flashCard/mp3/' + card + '.mp3');
                  dispatch(setPlay(true));
                }
              }}
            />
          )
        }))}
      </CardWrap>
      <AudioPlayer
        ref={player}
        src={'https://content-cdn.chungchy.com/Phonics_Hero/' + mp3}
        autoPlayAfterSrcChange={state.card.play}
        showSkipControls={true}
        showJumpControls={false}
        hasDefaultKeyBindings={false}
        preload="true"
        onEnded={() => {
          setAct(false);
          setChoice(null);
          setMp3("");
          dispatch(setPlay(false));

          let array = back;
          array[choice] = !array[choice];

          setBack(array);
        }}
      />
    </>
  );
};


