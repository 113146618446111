import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import styled from 'styled-components';
import { Readers } from '../../../asset/utils/Readers';
import { setBtnSound } from '../../../store/CommonSlice';
import { resetRecord, resetSpeaking, setInfo, setMic, setRecord, setRecordEffect, setRecordPlay, setScore, setScoreName, setScoreWord, setSpeakingPop } from '../../../store/SpeakingSlice';
import { Image } from '../../_atoms/Image';
import { Frame } from '../../_molecules/Frame';
import { ImageButton } from '../../_molecules/ImageButton';
import { PopupWrap } from '../../_organisms/PopupWrap';
import { SpeakingSentenceWrap } from '../../_organisms/SpeakingSentenceWrap';
import { SpeakingSlideWrap } from '../../_organisms/SpeakingSlideWrap';

const RightInfo = styled(ImageButton)`
  right: 4.8vw!important;
`;

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const SpeakingTemplate = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const audio = useRef();
  const recordAudio = useRef();

  const [recorder, setRecorder] = useState();
  const [blob, setBlob] = useState();
  const [audioUrl, setAudioUrl] = useState();

  useEffect(() => {
    if (audio.current) {
      state.speaking.record_effect ? audio.current.audio.current.play() : audio.current.audio.current.pause();
    }
  }, [state.speaking.record_effect]);

  useEffect(() => {
    recorder && recorder.startRecording();
  }, [recorder]);

  useEffect(() => {
    if (blob) {
      dispatch(setRecord(false));
      setAudioUrl(URL.createObjectURL(blob));

      const formData = new FormData();
      formData.append('sentence', Readers[state.book.current_book - 1][state.book.current_readers].sentence[state.speaking.num]);
      formData.append('device', 'web');
      formData.append('record', blob, 'soundBlob.wav', 'audio/wav');

      axios({
        url : 'https://voice-recognition.chungchy.com/glb_service_send.php',
        method : 'POST',
        contentType : false,
        data : formData
      }).then((res) => {
        if (res.status === 200) {
          dispatch(setInfo(true));
        }

        const data = res.data;

        if (data < 0) {
          dispatch(setScoreName('oops'));
        } else if (0 <= data.score_acoustic && data.score_acoustic <= 40) {
          dispatch(setScoreName('readStory_again'));
        } else if (40 < data.score_acoustic && data.score_acoustic <= 70) {
          dispatch(setScoreName('readStory_good'));
        } else if (70 < data.score_acoustic) {
          dispatch(setScoreName('readStory_great'));
        }
        
        dispatch(setScore(data.score_acoustic));
        dispatch(setScoreWord(data.score_word_list));
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [blob]);

  useEffect(() => {
    setAudioUrl(null);
    dispatch(resetRecord());
  }, [state.speaking.num]);

  useEffect(() => {
    if (recordAudio.current) {
      state.speaking.record_play ? recordAudio.current.audio.current.play() : recordAudio.current.audio.current.pause();
      
      if (!state.speaking.record_play) {
        recordAudio.current.audio.current.currentTime = 0;
      }
    }
  }, [state.speaking.record_play]);

  // 녹음시작
  const onRecord = () => {
    // 마이크 권한 확인 후 녹음 시작
    navigator.mediaDevices.getUserMedia({audio : true}).then((stream) => {
      dispatch(setMic(true));
      dispatch(setRecordEffect(true));
      dispatch(setScoreWord(null));

      setRecorder(RecordRTC(stream, {
        recorderType          : StereoAudioRecorder,
        mimeType              : 'audio/wav',
        numberOfAudioChannels : 1,
        desiredSampRate       : 16000,
      }));
    }).catch((error) => {
      if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        alert("Your microphone is not connected.\r\nPlease connect your microphone.");
        dispatch(setMic(false));
      } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        alert("Please allow microphone permission.");
        dispatch(setMic(false));
      }
    });
  };

  // 녹음종료
  const offRecord = () => {
    recorder.stopRecording(() => {
      setBlob(recorder.getBlob());
    });
  };
  
  return (
    <>
      {state.speaking.pop && (
        <PopupWrap type="info">
          <Image src="https://content-cdn.chungchy.com/Phonics_Hero/images/common/info-content.png" alt="Meaning of Colors" type="infoPop" />
          <RightInfo
            type='info'
            src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/icn-info.png'
            alt='info'
            onClick={() => {
              dispatch(setBtnSound(true));
              dispatch(setSpeakingPop(false));
            }}
          />
        </PopupWrap>
      )}

      <Frame type="speaking">
        <ImageButton 
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_pre_150px.png'
          alt='back'
          type='back'
          name='left'
          onClick={() => {
            dispatch(setBtnSound(true));
            dispatch(resetSpeaking());
            navigate("/book/readers");
          }}
        />

        <SpeakingSlideWrap />
        
        <ImageButton
          type="record"
          src={state.speaking.record ? "https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-stop-on.png" : "https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-mike-on.png"}
          alt="record"
          act={state.speaking.record}
          onClick={() => {
            !state.speaking.play && !state.speaking.record_play && (state.speaking.record ? offRecord() : onRecord(true));
          }}
        />
        <ImageButton
          type='recordPlay'
          src={audioUrl ? (state.speaking.record_play ? 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-pause-on.png' : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-play-on.png') : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-play-off.png'}
          alt='record play'
          onClick={() =>
            !state.speaking.play && !state.speaking.record && (state.speaking.record_play ? dispatch(setRecordPlay(false)) : dispatch(setRecordPlay(true)))
          }
        />
        <AudioPlayer
          ref={recordAudio}
          src={audioUrl}
          autoPlayAfterSrcChange={false}
          showSkipControls={true}
          showJumpControls={false}
          hasDefaultKeyBindings={false}
          preload="true"
          onEnded={() => {
            dispatch(setRecordPlay(false));
            recordAudio.current.audio.current.currentTime = 0;
          }}
        />

        {state.speaking.record_effect && (
          <>
            <Image 
              type="recordStatus"
              src="https://content-cdn.chungchy.com/Phonics_Hero/images/common/ready.png"
              alt="ready"
            />
            <AudioPlayer
              ref={audio}
              src="https://content-cdn.chungchy.com/Phonics_Hero/mp3/Ready_Go.mp3"
              autoPlayAfterSrcChange={false}
              showSkipControls={true}
              showJumpControls={false}
              hasDefaultKeyBindings={false}
              preload="true"
              onEnded={() => {
                dispatch(setRecordEffect(false));
                dispatch(setRecord(true));
              }}
            />
          </>
        )}

        {!state.speaking.record_effect && !state.speaking.record && state.speaking.score_name && (
          <>
          <Image 
            type="recordStatus"
            src={"https://content-cdn.chungchy.com/Phonics_Hero/images/common/"+state.speaking.score_name+".png"}
            alt={state.speaking.score_name}
          />
          </>
        )}

        <SpeakingSentenceWrap />
      </Frame>
    </>
  );
};