import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { setAudioCurrentRange, setAudioCurrentTime } from '../../../store/AudioSlice';
import { setVideoChangeRange, setVideoCurrentRange } from '../../../store/VideoSlice';
import { Input } from '../../_atoms/Input';

const BarWrap = styled.div`
  ${(props) => (props.name === 'video' && css`flex: auto;`)}
`;

export const Progress = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <BarWrap name={props.name}>
      <Input 
        type="range" 
        name={props.name} 
        max={100}
        value={props.name === 'audio' ? (isNaN(state.audio.current_range) ? 0 : state.audio.current_range) : (isNaN(state.video.current_range) ? 0 : state.video.current_range)} 
        onChange={(e) => {
          if (props.name === 'audio') {
            dispatch(setAudioCurrentRange(e.target.value));
            dispatch(setAudioCurrentTime(parseInt(props.player.audio.current.duration * e.target.value / 100)));
            props.player.audio.current.currentTime = parseInt(props.player.audio.current.duration * e.target.value / 100);
          } else {
            dispatch(setVideoCurrentRange(e.target.value));
            dispatch(setVideoChangeRange(e.target.value));
          }
        }}
      />
    </BarWrap>
  );
};