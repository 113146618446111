import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setBtnSound } from '../../../store/CommonSlice';
import { Div } from '../../_atoms/Div';
import { Frame } from '../../_molecules/Frame';
import { ImageButton } from '../../_molecules/ImageButton';
import { BookMenuWrap } from '../../_organisms/BookMenuWrap';
import { SoundWrap } from '../../_organisms/SoundWrap';

export const BookTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <Frame type={"main"}>
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_home_150px.png'
        alt='home'
        type='home'
        onClick={() => {
          navigate("/");
          dispatch(setBtnSound(true));
        }}
      />
      <Div type="book" />
      <BookMenuWrap />
      <SoundWrap />
    </Frame>
  );
};