import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const ITag = styled.i`
  font-family: "Phosphor-Fill" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ${(props) => {
    switch (props.type) {
      case "key":
        return css`
          position: absolute;
          right: 1vw;
          top: 1vw;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 6vw;
          height: 6vw;
          border-radius: 3vw;
          background-color: rgba(0, 0, 0, 0.7);
          font-size: 4.2vw;
          color: #fff;
          transition: 0.6s cubic-bezier(0.16, 1, 0.3, 1);
          
          &::before {
            content: "\\ebd3";
          }
        `;
      default:
    }
  }}
`;

export const Icon = (props) => {
  return (
    <ITag type={props.type}/>
  );
};

Icon.propTypes = {
  type : PropTypes.string,
};

Icon.defaultProps = {
  type : 'key'
};