import React from 'react';
import { PlayBar } from '../../_molecules/PlayBar';
import { PlayButton } from '../../_molecules/PlayButton';

export const AudioControlWrap = () => {
  return (
    <>
      <PlayButton />
      <PlayBar />
    </>
  );
};