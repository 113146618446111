import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const Btn = styled.button`
  ${(props) => {
    switch (props.type) {
      case "mainBook" :
        return css`
          position: relative;
          transition: filter 0.6s cubic-bezier(0.16, 1, 0.3, 1);
          width: 20.3125vw;
          height: 26.302083vw;
          filter: drop-shadow(0.520833vw 0.520833vw rgba(0, 0, 0, 0.15));
      
          &:hover {
            filter: drop-shadow(0 0 rgba(0, 0, 0, 0.15));
      
            img {
              top: 0.520833vw;
              left: 0.520833vw;
            }

            i {
              right: 0.479167vw;
              top: 1.520833vw;
            }
          }
        `;
      case "home": case "back":
        return css`
          top: ${props.type === 'home' ? "1.5625vw;" : (props.name === 'left' ? "1.5625vw;" : "10.8625vw;")}
          position: absolute;
          right: ${props.name === 'left' ? "unset" : "2.083333vw"};
          left: ${props.name === 'left' ? '1.5625vw' : 'unset'};
          width: 8.854167vw;
          height: 8.854167vw;
          transition: transform 0.1s;

          &:active {
            transform: scale(95%);
          }
        `;
      case "book":
        return css`
          transition: transform 0.1s;

          &:active {
            transform: scale(97%);
          }
        `;
      case "pre": case "play": case "next":
        return css`
          width: 8.90625vw;
          height: 8.072917vw;
          background: center /contain no-repeat;
          margin : ${props.type === 'play' && '0 1.5625vw'};

          &:active {
            transform: scale(95%);
          }
        `;
      case "repeat":
        return css`
          width: 2.604167vw;
          height: 3.28125vw;
          background: ${props.act ? 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_btn_repeat_on.png)' : 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_btn_repeat_off.png)'} center /contain no-repeat;
        `;
      case "unit":
        return css`
          transition: transform 0.1s;

          &:active {
            transform: scale(102%);
          }
        `;
      case "close":
        return css`
          position: absolute;
          top: -1.666667vw;
          right: -1.25vw;
          width: 4.895833vw;
          height: 4.895833vw;
        `;
      case "popupUnit":
        return css`
          width: 100%;
          border-radius: 2.604167vw;
          background-color: #ffce47;
          box-shadow: 0 0.520833vw 0 0 #dcb400;
          font-family: inherit;
          font-size: 2.708333vw;
          font-weight: 800;
          line-height: 1;
          color: #fff;
          text-shadow: 0 0.260417vw 0 #ffbb59;
          transition: transform 0.1s;

          &:active {
            transform: scale(97%);
          }
        `;
      case "popupReaders":
        return css`
          width: 100%;
          border-radius: 2.604167vw;
          font-family: inherit;
          font-weight: 800;
          line-height: 1;
          color: #fff;
          position: relative;
          grid-column: span 2;
          background-color: #71a6ff;
          box-shadow: 0 0.520833vw 0 0 #488cff;
          font-size: 3.75vw;
          text-shadow: 0 0.260417vw 0 #347efd;
          transition: transform 0.1s;

          &:active {
            transform: scale(97%);
          }
        `;
      case "card":
        return css`
          width: 18.618229vw;
          height: 23.010938vw;
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/${props.src}.png);

          &.ani1 {
            transform: scale(100%);
            animation: ani1 0.6s ease-in-out;
            animation-play-state: running;
          }
        
          @keyframes ani1 {
            0% {
              transform: scale(100%);
            }
        
            50% {
              transform: scale(110%);
            }
        
            100% {
              transform: scale(100%);
            }
          }

          &.ani2 {
            animation: ani2 0.6s ease-in-out;
            animation-play-state: running;
          }

          @keyframes ani2 {
            0% {
              transform: scale(100%);
            }

            50% {
              transform: scale(110%);
            }

            100% {
              transform: scale(100%);
            }
          }

          &.ani3 {
            transform: scale(100%);
            animation: ani3 0.6s ease-in-out;
            animation-play-state: running;
          }

          @keyframes ani3 {
            0% {
              transform: scale(70%);
            }

            100% {
              transform: scale(100%);
            }
          }

          &.ani4 {
            transform: scale(70%);
            animation: ani4 0.6s ease-in-out;
            animation-play-state: running;
          }

          @keyframes ani4 {
            0% {
              transform: scale(100%);
            }

            100% {
              transform: scale(70%);
            }
          }
        `
      case "word":
        return css`
          width: 9.583333vw;
          height: 5.989583vw;
          padding-bottom: 0.78125vw;
          background: center /contain no-repeat;
          font-family: inherit;
          font-size: 2.604167vw;
          font-weight: 800;
          color: ${props.act ? 'rgba(0, 0, 0, 0.5)' : '#fff'};
          background-image: ${(props) => {
            switch (props.name) {
              case "blue":
                return props.act ? 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_btn_blue_push.png)' : 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_btn_blue_basic.png)'
              case "green":
                return props.act ? 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_btn_green_push.png)' : 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_btn_green_basic.png)'
              case "pink":
                return props.act ? 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_btn_pink_push.png)' : 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_btn_pink_basic.png)'
              default:
            }
          }};
          ${props.act && 'background-position: bottom; padding: 0.260417vw;'}

          transition: transform 0.1s;

          &:active {
            transform: scale(97%);
          }
        `
      case "videoPlay":
        return css`
          width: 2.708333vw;
          height: ${props.act ? "2.708333vw" : "3.125vw"};
          background: ${props.act ? 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/chant_btn_pause.png) center /contain no-repeat' : 'url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/chant_btn_play.png) center /contain no-repeat'};
          margin: 0 7.135417vw 0 4.6875vw;
        `
      case "readers":
        return css`
          transition: transform 0.1s;

          &:active {
            transform: scale(102%);
          }
        `
      case "start":
        return css`
          width: 28.645833vw;
          height: 8.333333vw;
          border-radius: 2.604167vw;
          background-color: #71a6ff;
          box-shadow: 0 0.520833vw 0 0 #488cff;
          font-family: inherit;
          font-size: 4.6875vw;
          font-weight: 800;
          color: #fff;
          text-shadow: 0 0.260417vw 0 #347efd;

          transition: transform 0.1s;

          &:active:not(:disabled) {
            transform: scale(102%);
          }
          
          &:disabled {
            cursor: default;
            filter: grayscale(1);
          }
        `
      case "readersPre":
        return css`
          position: absolute;
          top: 22.473958vw;
          left: 5vw;

          transition: transform 0.1s;

          &:active {
            transform: scale(102%);
          }
        `
      case "readersNext":
        return css`
          position: absolute;
          top: 22.473958vw;
          right: 5vw;

          transition: transform 0.1s;

          &:active {
            transform: scale(102%);
          }
        `
      case "record": case "recordPlay":
        return css`
          position: absolute;
          top: ${props.type === 'record' ? '2.2625vw' : '10.1625vw'};
          right: 2.083333vw;

          transition: transform 0.1s;

          &:active {
            transform: scale(102%);
          }

          ${props.type === 'record' && props.act && css`animation: blinking 2s ease infinite alternate;`}

          @keyframes blinking {
            0% {
              opacity: 1;
            }

            50% {
              opacity : 0.3;
            }

            100% {
              opacity: 1;
            }
          }
        `
      case "speaker":
        return css`
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -0.520833vw;
          left: -0.520833vw;
          width: 4.6875vw;
          height: 4.6875vw;
          border-radius: 2.34375vw;
          background-color: #fff;
          box-shadow: 0 0.052083vw 0.15625vw 0 rgba(0, 0, 0, 0.3);

          transition: transform 0.1s;

          &:active {
            transform: scale(102%);
          }
        `
      case "info":
        return css`
          position: absolute;
          width: 4vw;
          height: 4vw;
          bottom: 1vw;
          right: 1vw;

          transition: transform 0.1s;

          &:active {
            transform: scale(102%);
          }
        `
      case "auth":
        return css`
          transition: transform 0.1s;
          font-weight: 800;
          line-height: 1;
          color: #fff;
          font-family: inherit;
          width: 100%;
          position: relative;
          background-color: #71a6ff;
          box-shadow: 0 0.520833vw 0 0 #488cff;
          text-shadow: 0 0.260417vw 0 #347efd;
          grid-column: span 1;
          font-size: 3vw;
          padding: 1vw 1vw 0.6vw;
          border-radius: 1.5vw;
      }
        `
      default:
    }
  }}
`;

export const Button = (props) => {
  const state = useSelector((state) => state.book);
  return (
    <Btn 
      type={props.type}
      name={props.name}
      act={props.act}
      src={props.src}
      book={state.current_book}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
    >
      {props.children}
    </Btn>
  );
};

Button.propTypes = {
  type : PropTypes.string,
  act : PropTypes.bool
};

Button.defaultProps = {
  type : 'mainBook'
};