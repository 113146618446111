import React from 'react';
import styled from 'styled-components';

const WrapDiv = styled.div`
  width : 100vw;
  height : 56.25vw;
`;

export const Wrap = (props) => {
  return (
    <WrapDiv>
      {props.children}
    </WrapDiv>
  );
};