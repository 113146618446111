import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { FlashWord } from '../../../asset/utils/FlashWord';
import { setCurrentWord } from '../../../store/CardSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { Button } from '../../_atoms/Button';
import { Div } from '../../_atoms/Div';
import { Span } from '../../_atoms/Span';
import { Frame } from '../../_molecules/Frame';
import { ImageButton } from '../../_molecules/ImageButton';
import { ContentsUnitWrap } from '../../_organisms/ContentsUnitWrap';
import { PopupWrap } from '../../_organisms/PopupWrap';
import { SoundWrap } from '../../_organisms/SoundWrap';

export const ContentsTemplate = () => {
  const state = useSelector((state) => state.book);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
       {(state.pop && state.type === 'unit') && (
        <PopupWrap 
          type={state.type} 
          title={<>Unit&nbsp;{(state.current_unit + 1)}</>} 
          subTitle={<>{FlashWord[state.current_book - 1][state.current_unit].button.map((label, idx) => {
            return (idx > 0 ? " " + label : label);
          })}</>}
        >
          <Button 
            type="popupUnit" 
            onClick={() => {
              dispatch(setBtnSound(true));
              navigate("/book/contents/flashCard/sound"); 
            }}>
              Flash cards
              <Span type="popup">(Sound)</Span>
          </Button>
          <Button 
            type="popupUnit" 
            onClick={() => {
              dispatch(setCurrentWord(FlashWord[state.current_book - 1][state.current_unit].button[0]));
              dispatch(setBtnSound(true));
              navigate("/book/contents/flashCard/word"); 
            }}
            >
            Flash cards
            <Span type="popup">(Word)</Span>
          </Button>
          <Button 
            type="popupUnit" 
            onClick={() => {
              dispatch(setBtnSound(true));
              navigate("/book/contents/chant"); 
            }}
          >
            Chant
          </Button>
          <Button 
            type="popupUnit" 
            onClick={() => {
              dispatch(setBtnSound(true));
              navigate("/book/contents/story"); 
            }}
          >
            Story
          </Button>
        </PopupWrap>
      )}
      {(state.pop && state.type === 'readers') && (
        <PopupWrap 
          type={state.type} 
          title={<>Unit&nbsp;{(state.current_unit + 1)}</>} 
          subTitle="Review" 
        >
          <Button 
            type="popupReaders" 
            onClick={() => {
              dispatch(setCurrentWord(FlashWord[state.current_book - 1][state.current_unit].button[0]));
              dispatch(setBtnSound(true));
              navigate("/book/contents/flashCard/word");
            }}
          >
            Flash cards (Word)
          </Button>
          <Button 
            type="popupReaders" 
            onClick={() => {
              navigate("/book/contents/readers"); 
              dispatch(setBtnSound(true));
            }}
          >
            Readers
          </Button>
        </PopupWrap>
      )}

      <Frame type="contents" >
        <ImageButton 
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_home_150px.png'
          alt='home'
          type='home'
          onClick={() => {
            navigate("/");
            dispatch(setBtnSound(true));
          }}
        />
        <ImageButton 
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_pre_150px.png'
          alt='back'
          type='back'
          onClick={() => {
            navigate("/book");
            dispatch(setBtnSound(true));
          }}
        />
        <Div type="book" />
        <ContentsUnitWrap card={FlashWord[state.current_book - 1]} />
        <SoundWrap />
      </Frame>
    </>
  );
};