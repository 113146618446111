import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { setBtnSound } from '../../../store/CommonSlice';
import { Div } from '../../_atoms/Div';
import { ImageButton } from '../../_molecules/ImageButton';

const MenuWrap = styled.div`
  display: grid;
  grid-template: repeat(3, auto) / repeat(2, auto);
  width: max-content;
  grid-gap: 2.083333vw 4.114583vw;
  padding: 4.739583vw;
  border-radius: 2.083333vw;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 3.229167vw auto 0;
`;

export const BookMenuWrap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <MenuWrap>
      <Div type="cover" />
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/book_btn_audio.png'
        alt='audio'
        type='book'
        onClick={() => {
          navigate("/book/audio");
          dispatch(setBtnSound(true));
        }}
      />
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/book_btn_contents.png'
        alt='audio'
        type='book'
        onClick={() => {
          navigate("/book/contents");
          dispatch(setBtnSound(true));
        }}
      />
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/book_btn_readers.png'
        alt='audio'
        type='book'
        onClick={() => {
          navigate("/book/readers");
          dispatch(setBtnSound(true));
        }}
      />
    </MenuWrap>
  );
};