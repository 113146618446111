import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const InsTag = styled.ins`
  ${(props) => {
    switch (props.type) {
      case "popup":
        return css`
          text-decoration: none;
          color: #fff;
        `
      default:
    }
  }}
`;

export const Ins = (props) => {
  return (
    <InsTag type={props.type}>{props.children}</InsTag>
  );
};

Ins.propTypes = {
  type : PropTypes.string,
};

Ins.defaultProps = {
  type : 'popup'
};