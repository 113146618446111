import { PropTypes } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { resetBookAuth } from '../../../store/BookAuthSlice';
import { setPop } from '../../../store/BookSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { setSpeakingPop } from '../../../store/SpeakingSlice';
import { Headline } from '../../_atoms/Headline';
import { Ins } from '../../_atoms/Ins';
import { Span } from '../../_atoms/Span';
import { ImageButton } from '../../_molecules/ImageButton';

const PopupBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  width: 100vw;
  height: 56.25vw;
`;

const Popup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 57.291667vw;
  border-radius: 2.083333vw;
  padding: 1.041667vw;
  height: ${(props) => props.type === "auth" ? 'auto' : '38.645833vw'};
  
  ${(props) => {
    switch (props.type) {
      case "unit":
        return css`background-color: #ffd666;`
      case "readers": case "auth":
        return css`background-color: #7cadff;`
      default:
    }
  }}
`;

const PopupContents = styled.div`
  ${(props) => {
    if (props.type === "auth") {
      return css`
        flex: auto;
        display: grid;
        grid-template: repeat(2, auto) / repeat(2, 1fr);
        grid-gap: 2.083333vw 1.5625vw;
        grid-row-gap: 2.783333vw;
        padding: 4.375vw 4.947917vw;
        border-radius: 0 0 1.241667vw 1.241667vw;
        background-color: #fff;
      `
    } else {
      return css`
        flex: auto;
        display: grid;
        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
        grid-gap: 2.083333vw 1.5625vw;
        padding: 4.375vw 4.947917vw;
        border-radius: 0 0 1.241667vw 1.241667vw;
        background-color: #fff;
      `
    }
  }}
`;

export const PopupWrap = (props) => {
  const dispatch = useDispatch();
  
  return (
    <PopupBg>
      {props.type !== 'info' ? (
        <Popup type={props.type}>
          <Headline size={1} type="popup">
            {props.title && (
              <Span type={props.type}>
                {props.title}
              </Span>
            )}
            <Ins type="popup">{props.subTitle && props.subTitle}</Ins>
          </Headline>
          <PopupContents type={props.type}>
            {props.children}
          </PopupContents>
          <ImageButton
            src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/unit_popup_btn_close.png'
            alt='close'
            type='close'
            onClick={() => {
              dispatch(setBtnSound(true));
              if (props.type === 'info') {
                dispatch(setSpeakingPop(false));
              } else if (props.type === 'auth') {
                dispatch(resetBookAuth());
              } else {
                dispatch(setPop(false));
              }
            }}
          />
        </Popup>
      ) : props.children}
    </PopupBg>
  );
};

PopupWrap.propTypes = {
  type : PropTypes.string,
};

PopupWrap.defaultProps = {
  type : 'unit',
};