import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Readers } from '../../../asset/utils/Readers';
import { setCurrentReaders, setPop } from '../../../store/BookSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { ImageButton } from '../../_molecules/ImageButton';

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  grid-column-gap: 2.864583vw;
  margin-top: 10.416667vw;
`;

export const ReadersMenuWrap = (props) => {
  const state = useSelector((state) => state.book);
  const dispatch = useDispatch();

  return (
    <BtnWrap class="unit_wrap">
      {Readers[state.current_book - 1].map((card, idx) => {
        return <ImageButton
                  src={'https://content-cdn.chungchy.com/Phonics_Hero/images/common/book' + (state.current_book) + "_readers" + (idx + 1) + ".png"}
                  alt='Readers'
                  type='readers'
                  onClick={() => {
                    dispatch(setCurrentReaders(idx));
                    dispatch(setBtnSound(true));
                    dispatch(setPop(true));
                  }}
                />
      })}
    </BtnWrap>
  );
};