import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const SmallTag = styled.small`
  ${(props) => {
    switch (props.type) {
      case "auth" :
        return css`
          display: block;
          color: red;
          margin-top: 1vw;
        `
      default:
    }
  }}
`;

export const Small = (props) => {
  return (
    <SmallTag type={props.type}>{props.children}</SmallTag>
  );
};

Small.propTypes = {
  type : PropTypes.string
};

Small.defaultProps = {
  type : 'auth'
};