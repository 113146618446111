import React from 'react';
import { useParams } from 'react-router-dom';
import { FlashCardTemplate } from '../../_templates/FlashCardTemplate';

export const FlashCard = () => {
  const { type } = useParams();

  return (
    <FlashCardTemplate type={type} />
  );
};