export const FlashSound = [
  [
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit1-A-Word",
              "sound": "book1/flashCard/mp3/Aa.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit1-B-Word",
              "sound": "book1/flashCard/mp3/Bb.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit1-C-Word",
              "sound": "book1/flashCard/mp3/Cc.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit1-ant",
              "sound": "book1/flashCard/mp3/ant.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit1-bear",
              "sound": "book1/flashCard/mp3/bear.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit1-cat",
              "sound": "book1/flashCard/mp3/cat.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit2-D-Word",
              "sound": "book1/flashCard/mp3/Dd.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit2-E-Word",
              "sound": "book1/flashCard/mp3/Ee.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit2-F-Word",
              "sound": "book1/flashCard/mp3/Ff.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit2-dog",
              "sound": "book1/flashCard/mp3/dog.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit2-elephant",
              "sound": "book1/flashCard/mp3/elephant.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit2-fish",
              "sound": "book1/flashCard/mp3/fish.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit3-G-Word",
              "sound": "book1/flashCard/mp3/Gg.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit3-H-Word",
              "sound": "book1/flashCard/mp3/Hh.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit3-I-Word",
              "sound": "book1/flashCard/mp3/Ii.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit3-goat",
              "sound": "book1/flashCard/mp3/goat.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit3-hippo",
              "sound": "book1/flashCard/mp3/hippo.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit3-iguana",
              "sound": "book1/flashCard/mp3/iguana.mp3"
          }
      ]
    },
    {},
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit5-J-Word",
              "sound": "book1/flashCard/mp3/Jj.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit5-K-Word",
              "sound": "book1/flashCard/mp3/Kk.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit5-L-Word",
              "sound": "book1/flashCard/mp3/Ll.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit5-jam",
              "sound": "book1/flashCard/mp3/jam.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit5-king",
              "sound": "book1/flashCard/mp3/king.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit5-lion",
              "sound": "book1/flashCard/mp3/lion.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit6-M-Word",
              "sound": "book1/flashCard/mp3/Mm.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit6-N-Word",
              "sound": "book1/flashCard/mp3/Nn.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit6-O-Word",
              "sound": "book1/flashCard/mp3/Oo.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit6-monkey",
              "sound": "book1/flashCard/mp3/monkey.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit6-nut",
              "sound": "book1/flashCard/mp3/nut.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit6-ox",
              "sound": "book1/flashCard/mp3/ox.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit7-P-Word",
              "sound": "book1/flashCard/mp3/Pp.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit7-Q-Word",
              "sound": "book1/flashCard/mp3/Qq.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit7-R-Word",
              "sound": "book1/flashCard/mp3/Rr.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit7-panda",
              "sound": "book1/flashCard/mp3/panda.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit7-queen",
              "sound": "book1/flashCard/mp3/queen.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit7-rabbit",
              "sound": "book1/flashCard/mp3/rabbit.mp3"
          }
      ]
    },
    {},
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit9-S-Word",
              "sound": "book1/flashCard/mp3/Ss.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit9-T-Word",
              "sound": "book1/flashCard/mp3/Tt.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit9-U-Word",
              "sound": "book1/flashCard/mp3/Uu.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit9-sit",
              "sound": "book1/flashCard/mp3/sit.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit9-tiger",
              "sound": "book1/flashCard/mp3/tiger.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit9-umbrella",
              "sound": "book1/flashCard/mp3/umbrella.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit10-V-Word",
              "sound": "book1/flashCard/mp3/Vv.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit10-W-Word",
              "sound": "book1/flashCard/mp3/Ww.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit10-X-Word",
              "sound": "book1/flashCard/mp3/Xx.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit10-vet",
              "sound": "book1/flashCard/mp3/vet.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit10-watch",
              "sound": "book1/flashCard/mp3/watch.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit10-fox",
              "sound": "book1/flashCard/mp3/fox.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book1/flashCard/B1-Unit11-Y-Word",
              "sound": "book1/flashCard/mp3/Yy.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit11-Z-Word",
              "sound": "book1/flashCard/mp3/Zz.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book1/flashCard/B1-Unit11-yacht",
              "sound": "book1/flashCard/mp3/yacht.mp3"
          },
          {
              "img": "book1/flashCard/B1-Unit11-zebra",
              "sound": "book1/flashCard/mp3/zebra.mp3"
          }
      ]
    }
  ],
  [
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit1-an-Word",
              "sound": "book2/flashCard/mp3/an.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit1-ap-Word",
              "sound": "book2/flashCard/mp3/ap.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit1-at-Word",
              "sound": "book2/flashCard/mp3/at.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit1-bat",
              "sound": "book2/flashCard/mp3/bat.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit1-fan",
              "sound": "book2/flashCard/mp3/fan.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit1-map",
              "sound": "book2/flashCard/mp3/map.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit2-et-Word",
              "sound": "book2/flashCard/mp3/et.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit2-ed-Word",
              "sound": "book2/flashCard/mp3/ed.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit2-en-Word",
              "sound": "book2/flashCard/mp3/en.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit2-pet",
              "sound": "book2/flashCard/mp3/pet.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit2-bed",
              "sound": "book2/flashCard/mp3/bed.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit2-hen",
              "sound": "book2/flashCard/mp3/hen.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit3-ip-Word",
              "sound": "book2/flashCard/mp3/ip.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit3-ig-Word",
              "sound": "book2/flashCard/mp3/ig.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit3-in-Word",
              "sound": "book2/flashCard/mp3/in.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit3-lip",
              "sound": "book2/flashCard/mp3/lip.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit3-dig",
              "sound": "book2/flashCard/mp3/dig.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit3-fin",
              "sound": "book2/flashCard/mp3/fin.mp3"
          }
      ]
    },
    {},
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit5-ot-Word",
              "sound": "book2/flashCard/mp3/ot.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit5-op-Word",
              "sound": "book2/flashCard/mp3/op.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit5-ox-Word",
              "sound": "book2/flashCard/mp3/ox.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit5-pot",
              "sound": "book2/flashCard/mp3/pot.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit5-mop",
              "sound": "book2/flashCard/mp3/mop.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit5-fox",
              "sound": "book2/flashCard/mp3/fox.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit6-ut-Word",
              "sound": "book2/flashCard/mp3/ut.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit6-ug-Word",
              "sound": "book2/flashCard/mp3/ug.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit6-un-Word",
              "sound": "book2/flashCard/mp3/un.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit6-hut",
              "sound": "book2/flashCard/mp3/hut.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit6-bug",
              "sound": "book2/flashCard/mp3/bug.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit6-fun",
              "sound": "book2/flashCard/mp3/fun.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit7-ake-Word",
              "sound": "book2/flashCard/mp3/ake.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit7-ame-Word",
              "sound": "book2/flashCard/mp3/ame.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit7-ave-Word",
              "sound": "book2/flashCard/mp3/ave.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit7-cake",
              "sound": "book2/flashCard/mp3/cake.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit7-game",
              "sound": "book2/flashCard/mp3/game.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit7-wave",
              "sound": "book2/flashCard/mp3/wave.mp3"
          }
      ]
    },
    {},
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit9-ide-Word",
              "sound": "book2/flashCard/mp3/ide.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit9-ike-Word",
              "sound": "book2/flashCard/mp3/ike.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit9-ive-Word",
              "sound": "book2/flashCard/mp3/ive.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit9-ride",
              "sound": "book2/flashCard/mp3/ride.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit9-hike",
              "sound": "book2/flashCard/mp3/hike.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit9-dive",
              "sound": "book2/flashCard/mp3/dive.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit10-ole-Word",
              "sound": "book2/flashCard/mp3/ole.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit10-ome-Word",
              "sound": "book2/flashCard/mp3/ome.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit10-one-Word",
              "sound": "book2/flashCard/mp3/one.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit10-hole",
              "sound": "book2/flashCard/mp3/hole.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit10-dome",
              "sound": "book2/flashCard/mp3/dome.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit10-bone",
              "sound": "book2/flashCard/mp3/bone.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book2/flashCard/B2-Unit11-une-Word",
              "sound": "book2/flashCard/mp3/une.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit11-ute-Word",
              "sound": "book2/flashCard/mp3/ute.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit11-ube-Word",
              "sound": "book2/flashCard/mp3/ube.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book2/flashCard/B2-Unit11-dune",
              "sound": "book2/flashCard/mp3/dune.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit11-cute",
              "sound": "book2/flashCard/mp3/cute.mp3"
          },
          {
              "img": "book2/flashCard/B2-Unit11-tube",
              "sound": "book2/flashCard/mp3/tube.mp3"
          }
      ]
    },
  ],
  [
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit1-bl-Word",
              "sound": "book3/flashCard/mp3/bl-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit1-cl-Word",
              "sound": "book3/flashCard/mp3/cl-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit1-fl-Word",
              "sound": "book3/flashCard/mp3/fl-.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit1-black",
              "sound": "book3/flashCard/mp3/black.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit1-clap",
              "sound": "book3/flashCard/mp3/clap.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit1-flag",
              "sound": "book3/flashCard/mp3/flag.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit2-gr-Word",
              "sound": "book3/flashCard/mp3/gr-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit2-dr-Word",
              "sound": "book3/flashCard/mp3/dr-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit2-tr-Word",
              "sound": "book3/flashCard/mp3/tr-.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit2-green",
              "sound": "book3/flashCard/mp3/green.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit2-dress",
              "sound": "book3/flashCard/mp3/dress.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit2-truck",
              "sound": "book3/flashCard/mp3/truck.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit3-sn-Word",
              "sound": "book3/flashCard/mp3/sn-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit3-st-Word",
              "sound": "book3/flashCard/mp3/st-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit3-sw-Word",
              "sound": "book3/flashCard/mp3/sw-.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit3-snake",
              "sound": "book3/flashCard/mp3/snake.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit3-stop",
              "sound": "book3/flashCard/mp3/stop.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit3-swing",
              "sound": "book3/flashCard/mp3/swing.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit4-ch-Word",
              "sound": "book3/flashCard/mp3/ch-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit4-sh-Word",
              "sound": "book3/flashCard/mp3/sh-.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit4-th-Word",
              "sound": "book3/flashCard/mp3/th-.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit4-chick",
              "sound": "book3/flashCard/mp3/chick.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit4-ship",
              "sound": "book3/flashCard/mp3/ship.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit4-think",
              "sound": "book3/flashCard/mp3/think.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit5-ch-Word",
              "sound": "book3/flashCard/mp3/-ch.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit5-sh-Word",
              "sound": "book3/flashCard/mp3/-sh.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit5-th-Word",
              "sound": "book3/flashCard/mp3/-th.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit5-bench",
              "sound": "book3/flashCard/mp3/bench.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit5-fish",
              "sound": "book3/flashCard/mp3/fish.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit5-bath",
              "sound": "book3/flashCard/mp3/bath.mp3"
          }
      ]
    },
    {},
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit7-ai-Word",
              "sound": "book3/flashCard/mp3/-ai.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit7-ay-Word",
              "sound": "book3/flashCard/mp3/-ay.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit7-snail",
              "sound": "book3/flashCard/mp3/snail.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit7-gray",
              "sound": "book3/flashCard/mp3/gray.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit8-ea-Word",
              "sound": "book3/flashCard/mp3/-ea.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit8-ee-Word",
              "sound": "book3/flashCard/mp3/-ee.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit8-seal",
              "sound": "book3/flashCard/mp3/seal.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit8-bee",
              "sound": "book3/flashCard/mp3/bee.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit9-oa-Word",
              "sound": "book3/flashCard/mp3/-oa.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit9-ow-Word",
              "sound": "book3/flashCard/mp3/-ow1.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit9-boat",
              "sound": "book3/flashCard/mp3/boat.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit9-snow",
              "sound": "book3/flashCard/mp3/snow.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit10-ou-Word",
              "sound": "book3/flashCard/mp3/-ou.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit10-ow-Word",
              "sound": "book3/flashCard/mp3/-ow2.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit10-cloud",
              "sound": "book3/flashCard/mp3/cloud.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit10-cow",
              "sound": "book3/flashCard/mp3/cow.mp3"
          }
      ]
    },
    {
      "card_front": [
          {
              "img": "book3/flashCard/B3-Unit11-oi-Word",
              "sound": "book3/flashCard/mp3/-oi.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit11-oy-Word",
              "sound": "book3/flashCard/mp3/-oy.mp3"
          }
      ],
      "card_back": [
          {
              "img": "book3/flashCard/B3-Unit11-coin",
              "sound": "book3/flashCard/mp3/coin.mp3"
          },
          {
              "img": "book3/flashCard/B3-Unit11-boy",
              "sound": "book3/flashCard/mp3/boy.mp3"
          }
      ]
    },
  ],
];