import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { AudioTrack } from '../../../asset/utils/AudioTrack';
import { resetAudio } from '../../../store/AudioSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { Frame } from "../../_molecules/Frame";
import { ImageButton } from '../../_molecules/ImageButton';
import { AudioControlWrap } from '../../_organisms/AudioControlWrap';
import { AudioTrackWrap } from '../../_organisms/AudioTrackWrap';

const Cd = styled.div`
  align-self: flex-end;
  justify-self: center;
  width: 31.25vw;
  height: 31.25vw;
  filter: drop-shadow(0 0.104167vw 0.15625vw rgba(0, 0, 0, 0.4));
  transform-origin: 50% 50%;
  animation: rotate 6s linear infinite;
  animation-play-state: paused;
  background: center /contain no-repeat;
  background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_cd${(props) => (props.book)}.png);

  &.act {
    animation-play-state: running;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  } 

  &.rewind {
    animation: rewind 0.5s linear 1;
    animation-play-state: running;
  }

  @keyframes rewind {
    100% {
      transform: rotate(-360deg);
    }
  }
`;

export const AudioTemplate = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <Frame type="audio">
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_home_150px.png'
        alt='home'
        type='home'
        onClick={() => {
          navigate("/");
          dispatch(setBtnSound(true));
          dispatch(resetAudio());
        }}
      />
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_pre_150px.png'
        alt='back'
        type='back'
        onClick={() => {
          navigate("/book");
          dispatch(setBtnSound(true));
          dispatch(resetAudio());
        }}
      />
      <AudioTrackWrap unit={AudioTrack[state.book.current_book - 1]} track={AudioTrack[state.book.current_book - 1][state.audio.current_unit]}/>
      <Cd 
        book={state.book.current_book} 
        className={[state.audio.play ? 'act' : '' , state.audio.rewind ? 'rewind' : ''].join(' ')}
      />
      <AudioControlWrap />
    </Frame>
  );
};