import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Span } from '../../_atoms/Span';

const CountWrap = styled.div`
  position: absolute;
  top: 0;
  right: 1.041667vw;
`;

export const SentenceCount = (props) => {
  return (
    <CountWrap>
      <Span type="">{props.sentence}</Span>/<Span type="">{props.total}</Span>
    </CountWrap>
  );
};

SentenceCount.propTypes = {
  sentence : PropTypes.number,
  total : PropTypes.number,
};

SentenceCount.defaultProps = {
  sentence : 1,
  total : 10
};