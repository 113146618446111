import React from 'react';
import styled from 'styled-components';
import { ToggleButton } from '../../_molecules/ToggleButton';

const Sound = styled.div`
  position: absolute;
  right: 3.333333vw;
  bottom: 1.822917vw;
  display: flex;
  align-items: center;
  grid-column-gap: 0.520833vw;
  color: #10795c;
  font-size: 2.083333vw;
  font-weight: bold;
`;

export const SoundWrap = () => {
  return (
    <Sound>
      sound
      <ToggleButton id="sound" true="on" false="off" src={'https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_button.png'} alt="sound button" />
    </Sound>
  );
};