import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

  body {margin: 0; }

  html { /*font-size: 10px, 기본값 16px의 62.5%*/
  font-size: 62.5%; }

  html,
  body { width: 100%; }

  body {
  font-size: 1em;  /*font-size: 10px*/
  line-height: 1.5;
  letter-spacing: -0.05em;
  -webkit-overflow-scrolling: touch; }

  html, body {width: 100%; min-width: 100%; height: 100%;}
  img {width: 100%;}
  html, body {width: 100%; height: 100%; margin: 0; overflow: hidden;}

  #wrap {
    width: 100%; 
    height: 100%; 
    position: relative; 
    background-color: black;
  }

  #box {
    width: 100%; 
    height: 100%; 
    margin: 0 auto;
    position: absolute; 
    transform: translate(-50%, -50%); 
    top: 50%; 
    left: 50%;
  }

  #root {
    width: 100%; 
    height: 100%;
  }
`;