import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import audio from "./store/AudioSlice";
import auth from "./store/AuthSlice";
import bookAuth from "./store/BookAuthSlice";
import book from "./store/BookSlice";
import card from "./store/CardSlice";
import common from "./store/CommonSlice";
import speaking from "./store/SpeakingSlice";
import video from "./store/VideoSlice";

const reducers = combineReducers({
  auth : auth.reducer,
  common : persistReducer({
    key : 'common',
    storage,
    blacklist : ['sound', 'btn_sound']
  }, common.reducer),
  book : persistReducer({
    key : 'book',
    storage,
    blacklist : ['pop', 'type'],
  }, book.reducer),
  audio: persistReducer({
    key : 'audio',
    storage,
    blacklist : ['play', 'rewind', 'repeat', 'current_unit', 'current_track', 'duration_time', 'current_time', 'current_range', 'play_unit', 'play_track'],
  }, audio.reducer),
  video: persistReducer({
    key : 'video',
    storage,
    blacklist : ['play', 'control', 'duration_time', 'current_time', 'current_range', 'change_range'],
  }, video.reducer),
  card : persistReducer({
    key : 'card',
    storage,
    blacklist : ['play', 'current_word']
  }, card.reducer),
  speaking : persistReducer({
    key : 'speaking',
    storage,
    blacklist : ['mic', 'info', 'pop', 'play', 'num', 'record', 'record_effect', 'record_play', 'score', 'score_name', 'score_word'],
  }, speaking.reducer),
  bookAuth : persistReducer({
    key : 'bookAuth',
    storage,
    blacklist : ['auth', 'book', 'code', 'result', 'check']
  }, bookAuth.reducer),
});

const persistConfig = {
  key : 'root',
  storage,
  blacklist : ['common', 'book', 'audio', 'video', 'card', 'speaking', 'bookAuth']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer : persistedReducer,
  middleware : [thunk]
});


