import React, { useEffect, useRef, useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Readers } from '../../../asset/utils/Readers';
import { setBtnSound } from '../../../store/CommonSlice';
import { setReadersPlay, setSpeakingPop } from '../../../store/SpeakingSlice';
import { Paragraph } from '../../_atoms/Paragraph';
import { Span } from '../../_atoms/Span';
import { ImageButton } from '../../_molecules/ImageButton';
import { SentenceCount } from '../../_molecules/SentenceCount';

const SentenceWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92.447917vw;
  height: 13.020833vw;
  border-radius: 1.041667vw 1.041667vw 0 0;
  background-color: #fff;
  font-size: 2.604167vw;
  font-weight: 800;
  margin: 0 auto;
`;

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const SpeakingSentenceWrap = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const audio = useRef();

  const [sentence, setSentence] = useState(Readers[state.book.current_book - 1][state.book.current_readers].sentence[state.speaking.num]);

  useEffect(() => {
    if (audio.current) {
      state.speaking.play ? audio.current.audio.current.play() : audio.current.audio.current.pause();
    }
  }, [state.speaking.play]);

  useEffect(() => {
    if (!state.speaking.score_word || state.speaking.score_word && state.speaking.score_word.length === 0 || typeof state.speaking.score_word === 'undefined') {
      setSentence(Readers[state.book.current_book - 1][state.book.current_readers].sentence[state.speaking.num]);
    } else {
      const html = state.speaking.score_word && state.speaking.score_word.map((word, idx) => {
        let score_name;
        
        if (0 <= word.score && word.score <= 40) {
          score_name = 'again';
        } else if (40 < word.score && word.score <= 70) {
          score_name = 'good';
        } else if (70 < word.score) {
          score_name = 'great';
        }
        
        if (idx > 0) {
          return <> <Span type={score_name}>{word.text}</Span></>;
        } else {
          return <><Span type={score_name}>{word.text}</Span></>;
        }
      });
      
      setSentence(html);
    }
  }, [state.speaking.score_word]);

  return (
    <SentenceWrap>
      {state.speaking.play ? 
        <ImageButton
          type='speaker'
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-speaker-ing.png'
          alt='play sentence'
          act={true}
        />
        :
        <ImageButton
          type='speaker'
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/readStory_btn-speaker.png'
          alt='play sentence'
          act={false}
          onClick={() => {
            !state.speaking.record_effect && !state.speaking.record && !state.speaking.record_play && dispatch(setReadersPlay(true));
          }}
        />
      }

      <AudioPlayer
        ref={audio}
        src={"https://content-cdn.chungchy.com/Phonics_Hero/book"+state.book.current_book+"/"+Readers[state.book.current_book - 1][state.book.current_readers].path[state.speaking.num]}
        autoPlayAfterSrcChange={false}
        showSkipControls={true}
        showJumpControls={false}
        hasDefaultKeyBindings={false}
        preload="true"
        onEnded={() => dispatch(setReadersPlay(false))}
      />
      
      <Paragraph type="" label={sentence} />
      
      <SentenceCount sentence={(state.speaking.num + 1)} total={Readers[state.book.current_book - 1][state.book.current_readers].sentence.length}/>
      
      {!state.speaking.record_effect && !state.speaking.record && state.speaking.info && (
        <ImageButton
          type='info'
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/icn-info.png'
          alt='info'
          onClick={() => {
            dispatch(setBtnSound(true));
            dispatch(setSpeakingPop(true));
          }}
        />
      )}
    </SentenceWrap>
  );
};