import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  play : false,
  rewind : false,
  repeat : false,
  current_unit : 0,
  current_track : 0,
  duration_time : '00:00',
  current_time : '00:00',
  current_range : 0,
  play_unit : 0, // 현재 재생되고 있는 유닛
  play_track : 0, // 현재 재생되고 있는 트랙
};

const audio = createSlice({
  name : "audio",
  initialState,
  reducers : {
    resetAudio : () => initialState,
    setAudioPlay(state, action) {
      state.play = action.payload;
    },
    setRewind(state, action) {
      state.rewind = action.payload;
    },
    setRepeat(state, action) {
      state.repeat = action.payload;
    },
    setCurrentUnit(state, action) {
      state.current_unit = action.payload;
    },
    setCurrentTrack(state, action) {
      state.current_track = action.payload;
    },
    setAudioDurationTime(state, action) {
      let minute = Math.floor(action.payload / 60);
      minute = minute < 10 ? "0" + minute.toString() : minute;
      
      let second = parseInt(action.payload - minute * 60);
      second = second < 10 ? "0" + second.toString() : second;

      state.duration_time = minute + ":" + second;
    },
    setAudioCurrentTime(state, action) {
      let minute = Math.floor(action.payload / 60);
      minute = minute < 10 ? "0" + minute.toString() : minute;
      
      let second = parseInt(action.payload - minute * 60);
      second = second < 10 ? "0" + second.toString() : second;

      state.current_time = minute + ":" + second;
    },
    setAudioCurrentRange(state, action) {
      state.current_range = action.payload;
    },
    setPlayUnit(state, action) {
      state.play_unit = action.payload;
    },
    setPlayTrack(state, action) {
      state.play_track = action.payload;
    },
  }
});

export const {
  resetAudio,
  setAudioPlay,
  setRewind,
  setRepeat,
  setCurrentUnit,
  setCurrentTrack,
  setAudioDurationTime,
  setAudioCurrentTime,
  setAudioCurrentRange,
  setPlayUnit,
  setPlayTrack,
} = audio.actions;

export default audio;