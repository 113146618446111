import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setBtnSound } from '../../../store/CommonSlice';
import { Button } from '../../_atoms/Button';
import { Div } from '../../_atoms/Div';
import { Frame } from '../../_molecules/Frame';
import { ImageButton } from '../../_molecules/ImageButton';
import { PopupWrap } from '../../_organisms/PopupWrap';
import { ReadersMenuWrap } from '../../_organisms/ReadersMenuWrap';
import { SoundWrap } from '../../_organisms/SoundWrap';

export const ReadersTemplate = () => {
  const state = useSelector((state) => state.book);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  return (
    <>
      {state.pop && (
        <PopupWrap 
          type="readers" 
          title=""
          subTitle={<>Readers&nbsp;{(state.current_unit + 1)}</>} 
        >
          <Button 
            type="popupReaders" 
            onClick={() => {
              dispatch(setBtnSound(true));
              navigate("/book/readers/story");
            }}
          >
            Listen to Story
          </Button>
          <ImageButton 
            type="popupReaders" 
            src="https://content-cdn.chungchy.com/Phonics_Hero/images/common/readers_popup_flag.png" 
            onClick={() => {
              dispatch(setBtnSound(true));
              navigate("/book/readers/speaking");
            }}
            label="Speaking" 
          />
        </PopupWrap>
      )}

      <Frame type="readers">
        <ImageButton 
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_home_150px.png'
          alt='home'
          type='home'
          onClick={() => {
            dispatch(setBtnSound(true));
            navigate("/");
          }}
        />
        <ImageButton 
          src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_pre_150px.png'
          alt='back'
          type='back'
          onClick={() => {
            dispatch(setBtnSound(true));
            navigate("/book");
          }}
        />
        <Div type="book" />
        <ReadersMenuWrap />
        <SoundWrap />
      </Frame>
    </>
  );
};