import { PropTypes } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { setAuth, setBook } from '../../../store/BookAuthSlice';
import { resetBook, setCurrentBook } from '../../../store/BookSlice';
import { setBtnSound } from '../../../store/CommonSlice';
import { Icon } from '../../_atoms/Icon';
import { ImageButton } from '../../_molecules/ImageButton';

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  grid-column-gap: 4.375vw;
  margin-top: 6.09375vw;
`;

export const MainBookWrap = (props) => {
  const {auth, book} = useSelector((state) => state.bookAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickBook = (idx) => {
    if (props.menu[idx].auth) {
      dispatch(setBtnSound(true));
      dispatch(setCurrentBook(idx + 1));
      dispatch(resetBook());
  
      navigate('/book');
    } else {
      dispatch(setAuth(true));
      dispatch(setBook(idx + 1));
    }
  };

  return (
    <BtnWrap>
      {props.menu && props.menu.map((book, idx) => {
        return <>
          <ImageButton
            type={props.type} 
            src={book.src} 
            alt={book.alt} 
            idx={idx}
            onClick={() => onClickBook(idx)}
            onMouseOver={() => dispatch(setBtnSound(true))}
          >
            {!book.auth && (
              <Icon type="key" />
            )}
            
          </ImageButton>
        </>
      })}
    </BtnWrap>
  );
};

MainBookWrap.propTypes = {
  type : PropTypes.string,
  menu : PropTypes.array
};

MainBookWrap.defaultProps = {
  type : "mainBook",
  menu : [
    {
      src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book1.png',
      alt : 'Phonics Hero Book1',
    },
    {
      src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book2.png',
      alt : 'Phonics Hero Book2',
    },
    {
      src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book3.png',
      alt : 'Phonics Hero Book3',
    },
  ]
};
