import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  play : false,
  current_word : '',
};

const card = createSlice({
  name : "card",
  initialState,
  reducers : {
    resetCard : () => initialState,
    setPlay(state, action) {
      state.play = action.payload;
    },
    setCurrentWord(state, action) {
      state.current_word = action.payload;
    },
  }
});

export const {
  resetCard,
  setPlay,
  setCurrentWord,
} = card.actions;

export default card;