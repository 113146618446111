import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sound : true,
  btn_sound : false,
};

const common = createSlice({
  name : "common",
  initialState,
  reducers : {
    setSound(state, action) {
      state.sound = action.payload;
    },
    setBtnSound(state, action) {
      state.btn_sound = action.payload;
    }
  }
});

export const {
  setSound,
  setBtnSound
} = common.actions;

export default common;