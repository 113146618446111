import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCookieToken, removeCookieToken, setRefreshToken } from '../../../storage/Cookie';
import { resetAudio } from '../../../store/AudioSlice';
import { resetAuth, setToken } from '../../../store/AuthSlice';
import { resetBook } from '../../../store/BookSlice';
import { resetCard } from '../../../store/CardSlice';
import { resetSpeaking } from '../../../store/SpeakingSlice';
import { resetVideo } from '../../../store/VideoSlice';

export const Auth = () => {
  const params = useParams();

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const accessToken = params['access'];
  const refreshToKen = params['refresh'];

  const [refreshCookie, setRefreshCookie] = useState(getCookieToken());

  useEffect(() => {
    dispatch(resetAuth());
    dispatch(resetAudio());
    dispatch(resetBook());
    dispatch(resetCard());
    dispatch(resetSpeaking());
    dispatch(resetVideo());
    removeCookieToken();
    setRefreshCookie(getCookieToken());
  }, [])

  useEffect(() => {
    if (!auth.authenticated && refreshCookie === undefined) {
      // access token 스토어 저장
      dispatch(setToken(accessToken));
  
      // refresh token 쿠키 저장
      setRefreshToken({refreshToKen});
      setRefreshCookie(getCookieToken());
    }
  }, [auth.authenticated, refreshCookie]);

  useEffect(() => {
    if (auth.authenticated && refreshCookie) {
      window.location.href="/";
    }
  }, [auth.authenticated, refreshCookie]);

  return (
    <></>
  );
};