import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { setBtnSound } from '../../../store/CommonSlice';
import { resetVideo, setControl } from '../../../store/VideoSlice';
import { ImageButton } from '../../_molecules/ImageButton';
import { VideoPlayBar } from '../../_molecules/VideoPlayBar';

const ControlWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-out;

  ${(props) => !props.act && css`opacity: 0;`}
`;

export const VideoControlWrap = (props) => {
  const state = useSelector((state) => state.video);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const timer = useRef();
  
  const onMouseMove = () => {
    dispatch(setControl(true));
    clearTimeout(timer.current);

    if (state.control) {
      timer.current = setTimeout(setTimer, 2000);
    }
  };

  const setTimer = () => {
    clearTimeout(timer.current);
    dispatch(setControl(false));
  };

  return (
    <ControlWrap act={state.control} onMouseOver={() => dispatch(setControl(true))} onMouseOut={() => dispatch(setControl(false))} onMouseMove={() => onMouseMove()}>
      <ImageButton
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_pre_150px.png'
        alt='back'
        type='back'
        name='left'
        onClick={() => {
          dispatch(setBtnSound(true));
          dispatch(resetVideo());
          state.type === 'stories' ? navigate("/book/readers") : navigate("/book/contents");
        }}
      />
      <VideoPlayBar />
    </ControlWrap>
  );
};