import { useEffect, useRef } from "react";
import H5AudioPlayer from "react-h5-audio-player";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";
import styled from "styled-components";
import { setBtnSound } from "../../../store/CommonSlice";
import { Audio } from "../../_atoms/Audio";

const AudioPlayer = styled(H5AudioPlayer)`
  width : 0;
  height : 0;
  opacity: 0;
  display: none;
`;

export const Sound = () => {
  const state = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const player = useRef();
  const audio = useRef();

  useEffect(() => {
    if (state.sound) {
      player.current && player.current.audio.current.play();
    } else {
      player.current && player.current.audio.current.pause();
    }

    if (player.current) {
      player.current.audio.current.loop = true;
    }
  }, [state.sound])

  useEffect(() => {
    if (state.sound && state.btn_sound) {
      audio.current && audio.current.audio.current.play();
      dispatch(setBtnSound(false));
    }
  }, [state.sound, state.btn_sound]);

  return (
    <>
      {state.sound && (
        <AudioPlayer
          ref={player}
          preload="true"
          src="https://content-cdn.chungchy.com/Phonics_Hero/mp3/homebgm_Bike_Rides.mp3"
          autoPlay={true}
        />
        )}
      <Outlet />
      <Audio
        ref={audio}
        src="https://content-cdn.chungchy.com/Phonics_Hero/mp3/btn.wav"
      />
    </>
  );
};