import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";
import { setBtnSound } from "../../../store/CommonSlice";
import { Audio } from "../../_atoms/Audio";

export const ButtonSound = () => {
  const state = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const audio = useRef();

  useEffect(() => {
    if (state.sound && state.btn_sound) {
      audio.current && audio.current.audio.current.play();
      dispatch(setBtnSound(false));
    }
  }, [state.sound, state.btn_sound]);

  return (
    <>
      <Audio
        ref={audio}
        src="https://content-cdn.chungchy.com/Phonics_Hero/mp3/btn.wav"
      />
      <Outlet />
    </>
  );
};