import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookieToken, removeCookieToken } from "../storage/Cookie";
import { deleteToken, setToken } from "../store/AuthSlice";
import { requestToken } from './../api/User';

const CheckToken = (key) => {
  const [isAuth, setIsAuth] = useState("Loading");
  const { authenticated, expire_time, access_token } = useSelector((state) => state.auth); // access token
  const refresh_token = getCookieToken(); // refresh token
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuthToken = async () => {
      // refresh token 확인
      if (refresh_token === undefined) {
        // refresh token 없을때
        // access token store에서 삭제
        dispatch(deleteToken());
        setIsAuth("Failed");
      } else {
        // access token 유효
        if (authenticated && new Date().getTime() < expire_time) {
          setIsAuth("Success");
          // access token 만료
        } else {
          // refresh token으로 access token 재발급
          const response = await requestToken({ refresh_token, access_token });

          if (response.status) {
            const token = response.json.info.info;
            dispatch(setToken(token));
            setIsAuth("Success");
          } else {
            dispatch(deleteToken());
            removeCookieToken();
            setIsAuth("Failed");
          }
        }
      }
    };

    checkAuthToken();
  }, [refresh_token, dispatch, key]);

  return {
    isAuth,
  };
};

export default CheckToken;
