import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AudioTrack } from '../../../asset/utils/AudioTrack';
import { setAudioPlay, setCurrentTrack, setCurrentUnit, setRewind } from '../../../store/AudioSlice';
import { ImageButton } from '../../_molecules/ImageButton';

const BtnWrap = styled.div`
  align-self: center;
  justify-self: center;
`;

export const PlayButton = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const onAudioPrev = () => {
    // 첫번째 트랙
    if (state.audio.current_track === 0 && state.audio.current_unit !== 0) {
      dispatch(setCurrentUnit(state.audio.current_unit - 1));
      dispatch(setCurrentTrack(AudioTrack[state.book.current_book - 1][state.audio.current_unit - 1].length -1));
    } else if (state.audio.current_track > 0) {
      dispatch(setCurrentTrack(state.audio.current_track - 1));
    }
  };

  const onAudioNext = () => {
    // 마지막 트랙
    if (state.audio.current_track === AudioTrack[state.book.current_book - 1][state.audio.current_unit].length - 1 && state.audio.current_unit !== AudioTrack[state.book.current_book - 1].length - 1) {
      dispatch(setCurrentUnit(state.audio.current_unit + 1));
      dispatch(setCurrentTrack(0));
    } else if (state.audio.current_track < AudioTrack[state.book.current_book - 1][state.audio.current_unit].length - 1) {
      dispatch(setCurrentTrack(state.audio.current_track + 1));
    }
  };
  
  return (
    <BtnWrap>
      <ImageButton
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_btn_pre.png'
        alt='pre'
        type='pre'
        onClick={() => onAudioPrev()}
      />
      <ImageButton 
        src={state.audio.play ? 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_btn_pause.png' : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_btn_play.png'}
        alt='play'
        type='play'
        onClick={() => {
          dispatch(setAudioPlay(!state.audio.play));
          dispatch(setRewind(false));
        }}
      />
      <ImageButton 
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_btn_next.png'
        alt='next'
        type='next'
        onClick={() => onAudioNext()}
      />
    </BtnWrap>
  );
};