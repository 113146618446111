import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const Img = styled.img`
  ${(props) => {
    switch (props.type) {
      case "main" :
        return css`
          margin-top: 4.84375vw;
          width: 51.614583vw;
          height: 8.125vw;
        `
      case "mainBook" :
        return css`
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.6s cubic-bezier(0.16, 1, 0.3, 1);
          width: 100%;
          height: auto;
        `
      case "sound" :
        return css`
          position: absolute;
          width: auto;
          height: 100%;
          transition: 0.3s;
          transition: 0.3s;
        `
      case "home": case "back":
        return css`
          width: 100%;
          height: auto;
        `
      case "book":
        return css`
          width: 26.143791vw;
          height: auto;
        `
      case "pre": case "play": case "next":
        return css`
          width: 8.90625vw;
          height: 8.072917vw;
          background: center /contain no-repeat;
        `
      case "unit":
        return css`
          width: ${(props) => props.book === 3 ? '12.020833vw' : '13.020833vw'};
          height: ${(props) => props.book === 3 ? '12.333333vw' : '13.333333vw'};
        `
      case "close":
        return css`
          width: 100%;
          height: auto;
        `
      case "title":
        return css`
          height: 100%;
          width: auto;
        `
      case "readers":
        return css`
          width: 16.666667vw;
          height: 19.583333vw;
        `
      case "popupReaders":
        return css`
          position: absolute;
          width: 7.239583vw;
          height: 7.239583vw;
          top: -0.208333vw;
          right: -0.208333vw;
        `
      case "speaking":
        return css`
          height: 100%;
          width: auto;
        `
      case "readersBook":
        return css`
          width: 100%;
          height: auto;
        `
      case "readersPre": case "readersNext":
        return css`
          width: 4.791667vw;
        `
      case "record": case "recordPlay":
        return css`
          width: 6.822917vw;
          height: 7.03125vw;
        `
      case "speaker":
        return css`
          width: 65%;
          ${props.act && css`

            animation: rotate 1.5s linear infinite;

            @keyframes rotate {
              0% {
                transform: rotate(0);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          `}
        `
      case "info":
        return css`
          width: 100%;
          height: auto;
        `
      case "infoPop":
        return css`
          width: 30.729167vw;
          height: 36.354167vw;
          position: absolute;
          right: 7.5vw;
          bottom: 2.5vw;
        `
      case "recordStatus":
        return css`
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);

          ${props.alt === 'ready' && css`width: 44.270833vw; top : 22.473958vw;`}
          ${props.alt === 'readStory_again' && css`width: 54.375vw; top : 19.473958vw;`}
          ${props.alt === 'readStory_good' && css`width: 35.625vw; top : 19.473958vw;`}
          ${props.alt === 'readStory_great' && css`width: 36.875vw; top : 19.473958vw;`}
          ${props.alt === 'oops' && css`width: 14.739583vw; top : 24.473958vw;`}
        `
      default :
    }
  }}

  ${(props) => (props.type === "sound" && (props.act ? 'left : calc(100% - 4.21875vw);' : 'left : 0;'))}
`;

export const Image = (props) => {
  const state =useSelector((state) => state.book);
  
  return (
    <Img 
      type={props.type}
      src={props.src}
      alt={props.alt}
      act={props.act}
      book={state.current_book}
    />
  );
};

Image.propTypes = {
  src : PropTypes.string.isRequired,
  alt : PropTypes.string.isRequired,
  type : PropTypes.string,
  act : PropTypes.bool,
  book : PropTypes.number
};

Image.defaultProps = {
  src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_title.png',
  alt : 'Phonics Hero',
  type : 'main',
  act : 'true'
};