import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const A = styled.a`
  cursor: pointer;

  ${(props) => {
    switch (props.type) {
      case "audioUnit":
        return css`
          display: block;
          height: 4.27484vw;
          line-height: 4.27484vw;
          font-size: 1.5625vw;
          font-weight: 600;
          color: ${props.act ? '#fff;' : '#d2d2d2'};
          ${props.act && 'background-color: #5493fc;'}
          text-align: center;
        `
      case "audioTrack":
        return css`
          display: block;
          height: 4.27484vw;
          line-height: 4.27484vw;
          font-size: 1.458333vw;
          color: ${props.act ? '#5493fc;' : '#d2d2d2'};
          ${props.act && 'background-color: #ddeaff;'}
          text-align: center;
        `
      default:
    }
  }};
`;

export const Anchor = (props) => {
  return (
    <A type={props.type} act={props.act} onClick={props.onClick}>{props.children}</A>
  );
};

Anchor.propTypes = {
  type : PropTypes.string,
  act : PropTypes.bool
};

Anchor.defaultProps = {
  type : 'audioUnit',
  act : true,
};