import { PropTypes } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSound } from '../../../store/CommonSlice';
import { Image } from '../../_atoms/Image';
import { Input } from '../../_atoms/Input';
import { Label } from '../../_atoms/Label';
import { Span } from '../../_atoms/Span';

export const ToggleButton = (props) => {
  const state = useSelector((state) => state.common);
  const dispatch = useDispatch();

  return (
    <>
      <Input type="checkbox" id={props.id} checked={state.sound} onChange={(e) => dispatch(setSound(!state.sound))} />
      <Label for={props.id}>
        <Span type={props.true} act={state.sound}/>
        <Span type={props.false} act={state.sound}/>
        <Image type={props.id} src={props.src} alt={props.alt} act={state.sound}/>
      </Label>
    </>
  );
};

ToggleButton.propTypes = {
  id : PropTypes.string,
  true : PropTypes.string,
  false : PropTypes.string,
  src : PropTypes.string,
  alt : PropTypes.string,
};

ToggleButton.defaultProps = {
  id : "sound",
  true : "on",
  false : "off",
  src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_button.png',
  alt : "sound button",
};

