import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestBook } from '../api/Book';

export const CheckBook = (key) => {
  const book = useSelector((state) => state.book);
  
  const { current_book } = useSelector((state) => state.book); // access token
  const { access_token } = useSelector((state) => state.auth); // access token

  const [isAuth, setIsAuth] = useState('Loading');

  useEffect(() => {
    const checkAuthBook = async () => {
      const response = await requestBook({current_book, access_token});

      if (response.status) {
        const book = response.json.info;

        if (book) {
          setIsAuth('Success');
        } else {
          setIsAuth('Failed');
        }
      } else {
        setIsAuth('Failed');
      }
    };

    checkAuthBook();
  }, [key]);
  
  return {
    isAuth,
  }
};