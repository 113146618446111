import React, { forwardRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Readers } from '../../../asset/utils/Readers';
import { resetVideo, setVideoCurrentRange, setVideoCurrentTime, setVideoDurationTime, setVideoPlay } from '../../../store/VideoSlice';

const VideoTag = styled.video`
  width: 100%;
  height: auto;
`;

export const Video = forwardRef((props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const player = useRef();

  useEffect(() => {
    if (player.current) {
      player.current.pause();
  
      state.video.play ? player.current.play() : player.current.pause();
    }
  }, [state.video.play]);

  useEffect(() => {
    player.current && dispatch(setVideoCurrentTime(parseInt(player.current.duration * state.video.change_range / 100)));

    if (player.current.currentTime) {
      player.current.currentTime = parseInt(player.current.duration * state.video.change_range / 100);
    }
  }, [state.video.change_range]);

  const onTimeUpdate = (e) => {
    player.current && dispatch(setVideoCurrentTime(player.current.currentTime));

    // progress bar 
    player.current && dispatch(setVideoCurrentRange((100 * parseInt(player.current.currentTime)) / parseInt(player.current.duration)));
  };

  return (
    <VideoTag
      ref={player}
      autoPlay={"autoplay"}
      onLoadedMetadata={() => {
        dispatch(setVideoCurrentTime(player.current.currentTime));
        dispatch(setVideoDurationTime(player.current.duration));
        dispatch(setVideoPlay(true));
      }}
      onTimeUpdate={(e) => {
        onTimeUpdate(e);
      }}
      onEnded={() => {
        dispatch(setVideoPlay(false));
        dispatch(resetVideo());
        state.video.type === 'stories' ? navigate('/book/readers') : navigate('/book/contents');
      }}
    >
      <source src={"https://content-cdn.chungchy.com/Phonics_Hero/book" + state.book.current_book + "/flashCard/mp4/" + (state.video.type === 'stories' ? 'readers' : state.video.type) + (state.video.type === "readers" ? Readers[state.book.current_book - 1].filter((key, idx) => key.unit === state.book.current_unit + 1)[0].num : (state.video.type === 'stories' ? (state.book.current_readers + 1) : ("_" + (state.book.current_unit + 1)))) + ".mp4"} />
    </VideoTag>
  );
});