import { PropTypes } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const MainBox = styled.main`
  position: relative;

  ${(props) => {
    switch (props.type) {
      case "main":
        return css`
          background: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_bg.png) center /contain no-repeat;
          text-align: center;
        `
      case "audio":
        return css`
          display: grid;
          grid-template-columns: 20% 80%;
          grid-template-rows: auto 8.072917vw 7.291667vw;
          grid-row-gap: 3.211806vw;
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_book${props.book}_bg_small.png);
        `
      case "contents":
        return css`
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/unitlist_book${props.book}_bg.png);
        `
      case "flashCard":
        return css`
          display: flex;
          flex-direction: column;
          grid-row-gap: 2.5vw;
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_book${props.book}_bg_gray.png);
        `
      case "readers":
        return css`
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/unitlist_book${props.book}_bg.png);
        `
      case "speakingInfo":
        return css`
          flex-direction: column;
          display: flex;
          grid-row-gap: 2.5vw;
          justify-content: center;
          align-items: center;
          text-align: center;
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_book${props.book}_bg_gray.png);
          `
      case "speaking":
        return css`
          text-align: center;
          background: center /contain no-repeat;
          background-image: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/flashcard_book${props.book}_bg_gray.png);
        `
      default:
    }
  }}
`;

export const Main = (props) => {
  const state = useSelector((state) => state.book);
  
  return (
    <MainBox type={props.type} book={state.current_book}>
      {props.children}
    </MainBox>
  );
};

Main.propTypes = {
  type : PropTypes.string,
  book : PropTypes.number,
};

Main.defaultProps = {
  book : 1,
};