export const FlashWord = [
  [
    {
      "unitNum" : [1,1,1],
      "button" : ["Aa", "Bb", "Cc"],
      "Aa": ["apple", "ant", "alligator"],
      "Bb": ["bear", "book", "banana"],
      "Cc": ["cat", "corn", "car"]
    },
    {
      "unitNum" : [2,2,2],
      "button" : ["Dd", "Ee", "Ff"],
      "Dd": ["dog", "donut", "drum"],
      "Ee": ["elephant", "egg", "elbow"],
      "Ff": ["fish", "frog", "fork"]
    },
    {
      "unitNum" : [3,3,3],
      "button" : ["Gg", "Hh", "Ii"],
      "Gg": ["goat", "gift", "girl"],
      "Hh": ["hat", "hippo", "house"],
      "Ii": ["iguana", "ink", "igloo"]
    },
    {
      "unitNum" : [1,1,1,2,2,2,3,3,3],
      "button" : ["Aa", "Bb", "Cc", "Dd", "Ee", "Ff", "Gg", "Hh", "Ii"],
      "Aa": ["apple", "ant", "alligator"],
      "Bb": ["bear", "book", "banana"],
      "Cc": ["cat", "corn", "car"],
      "Dd": ["dog", "donut", "drum"],
      "Ee": ["elephant", "egg", "elbow"],
      "Ff": ["fish", "frog", "fork"],
      "Gg": ["goat", "gift", "girl"],
      "Hh": ["hat", "hippo", "house"],
      "Ii": ["iguana", "ink", "igloo"]
    },
    {
      "unitNum" : [5,5,5],
      "button" : ["Jj", "Kk", "Ll"],
      "Jj": ["jam", "jet", "juice"],
      "Kk": ["king", "key", "kiwi"],
      "Ll": ["lion", "leaf", "lemon"]
    },
    {
      "unitNum" : [6,6,6],
      "button" : ["Mm", "Nn", "Oo"],
      "Mm": ["monkey", "melon", "milk"],
      "Nn": ["nut", "nail", "notebook"],
      "Oo": ["ox", "ostrich", "octopus"]
    },
    {
      "unitNum" : [7,7,7],
      "button" : ["Pp", "Qq", "Rr"],
      "Pp": ["panda", "pencil", "pie"],
      "Qq": ["queen", "quiet", "question"],
      "Rr": ["rabbit", "run", "robot"]
    },
    {
      "unitNum" : [5,5,5,6,6,6,7,7,7],
      "button" : ["Jj", "Kk", "Ll", "Mm", "Nn", "Oo", "Pp", "Qq", "Rr"],
      "Jj": ["jam", "jet", "juice"],
      "Kk": ["king", "key", "kiwi"],
      "Ll": ["lion", "leaf", "lemon"],
      "Mm": ["monkey", "melon", "milk"],
      "Nn": ["nut", "nail", "notebook"],
      "Oo": ["ox", "ostrich", "octopus"],
      "Pp": ["panda", "pencil", "pie"],
      "Qq": ["queen", "quiet", "question"],
      "Rr": ["rabbit", "run", "robot"]
    },
    {
      "unitNum" : [9,9,9],
      "button" : ["Ss", "Tt", "Uu"],
      "Ss": ["sit", "sun", "sock"],
      "Tt": ["tiger", "tent", "turtle"],
      "Uu": ["umbrella", "under", "up"]
    },
    {
      "unitNum" : [10,10,10],
      "button" : ["Vv", "Ww", "Xx"],
      "Vv": ["vet", "van", "violin"],
      "Ww": ["watch", "web", "window"],
      "Xx": ["fox", "box", "six"]
    },
    {
      "unitNum" : [11,11],
      "button" : ["Yy", "Zz"],
      "Yy": ["yacht", "yogurt", "yo-yo"],
      "Zz": ["zebra", "zoo", "zipper"]
    },
    {
      "unitNum" : [9,9,9,10,10,10,11,11],
      "button" : ["Ss", "Tt", "Uu", "Vv", "Ww", "Xx", "Yy", "Zz"],
      "Ss": ["sit", "sun", "sock"],
      "Tt": ["tiger", "tent", "turtle"],
      "Uu": ["umbrella", "under", "up"],
      "Vv": ["vet", "van", "violin"],
      "Ww": ["watch", "web", "window"],
      "Xx": ["fox", "box", "six"],
      "Yy": ["yacht", "yogurt", "yo-yo"],
      "Zz": ["zebra", "zoo", "zipper"]
    }
  ],
  [
    {
      "unitNum" : [1,1,1],
      "button" : ["-at", "-an", "-ap"],
      "-at": ["bat", "mat", "rat"],
      "-an":  ["fan", "can"],
      "-ap": ["map", "nap"]
    },
    {
      "unitNum" : [2,2,2],
      "button" : ["-et", "-ed", "-en"],
      "-et": ["pet", "net", "wet"],
      "-ed": ["bed", "red"],
      "-en": ["hen", "pen"]
    },
    {
      "unitNum" : [3,3,3],
      "button" : ["-ip", "-ig", "-in"],
      "-ip": ["lip", "zip", "hip"],
      "-ig": ["dig", "wig"],
      "-in": ["fin", "bin"]
    },
    {
      "unitNum" : [1,1,1,2,2,2,3,3,3],
      "button" : ["at", "an", "ap", "et", "ed", "en", "ip", "ig", "in"],
      "at": ["bat", "mat", "rat"],
      "an": ["fan", "can"],
      "ap": ["map", "nap"],
      "et": ["pet", "net", "wet"],
      "ed": ["bed", "red"],
      "en": ["hen", "pen"],
      "ip": ["lip", "zip", "hip"],
      "ig": ["dig", "wig"],
      "in": ["fin", "bin"]
    }, 
    {
      "unitNum" : [5,5,5],
      "button" : ["-ot", "-op", "-ox"],
      "-ot": ["pot", "hot", "dot"],
      "-op": ["mop", "pop"],
      "-ox": ["fox", "box"]
    },
    {
      "unitNum" : [6,6,6],
      "button" : ["-ut", "-ug", "-un"],
      "-ut": ["hut", "nut", "cut"],
      "-ug": ["mug", "bug"],
      "-un": ["fun", "run"]
    },
    {
      "unitNum" : [7,7,7],
      "button" : ["-ake", "-ame", "-ave"],
      "-ake":  ["cake", "rake", "bake"],
      "-ame": ["game", "name"],
      "-ave": ["wave", "cave"]
    },
    {
      "unitNum" : [5,5,5,6,6,6,7,7,7],
      "button" : ["ot", "op", "ox", "ut", "ug", "un", "ake", "ame", "ave"],
      "ot": ["pot", "hot", "dot"],
      "op": ["mop", "pop"],
      "ox": ["fox", "box"],
      "ut": ["hut", "nut", "cut"],
      "ug": ["mug", "bug"],
      "un": ["fun", "run"],
      "ake":  ["cake", "rake", "bake"],
      "ame": ["game", "name"],
      "ave": ["wave", "cave"]
    },
    {
      "unitNum" : [9,9,9],
      "button" : ["-ide", "-ike", "-ive"],
      "-ide": ["ride", "hide", "wide"],
      "-ike": ["hike", "bike"],
      "-ive": ["dive", "five"]
    },
    {
      "unitNum" : [10,10,10],
      "button" : ["-ole", "-ome", "-one"],
      "-ole": ["hole", "mole", "pole"],
      "-ome": ["dome", "home"],
      "-one": ["bone", "cone"]
    },
    {
      "unitNum" : [11,11,11],
      "button" : ["-une", "-ute", "-ube"],
      "-une": ["dune", "tune", "june"],
      "-ute": ["cute", "mute"],
      "-ube": ["tube", "cube"]
    },
    {
      "unitNum" : [9,9,9,10,10,10,11,11,11],
      "button" : ["ide", "ike", "ive", "ole", "ome", "one", "une", "ute", "ube"],
      "ide": ["ride", "hide", "wide"],
      "ike": ["hike", "bike"],
      "ive": ["dive", "five"],
      "ole": ["hole", "mole", "pole"],
      "ome": ["dome", "home"],
      "one": ["bone", "cone"],
      "une": ["dune", "tune", "june"],
      "ute": ["cute", "mute"],
      "ube": ["tube", "cube"]
    }
  ],
  [
    {
      "unitNum" : [1,1,1],
      "button" : ["bl", "cl", "fl"],
      "bl": ["black", "block", "blanket"],
      "cl": ["clap", "clock"],
      "fl": ["flag", "fly"]
    },
    {
      "unitNum" : [2,2,2],
      "button" : ["gr", "dr", "tr"],
      "gr": ["green", "grass", "grape"],
      "dr": ["dress", "dragon"],
      "tr":["truck", "tree"]
    },
    {
      "unitNum" : [3,3,3],
      "button" : ["sn", "st", "sw"],
      "sn": ["snake", "snack", "snore"],
      "st": ["stop", "stone"],
      "sw": ["swim", "swing"]
    },
    {
      "unitNum" : [4,4,4],
      "button" : ["ch", "sh", "th"],
      "ch": ["chick", "chin", "cheese"],
      "sh": ["ship", "shell"],
      "th": ["think", "three"]
    },
    {
      "unitNum" : [5,5,5],
      "button" : ["-ch", "-sh", "-th"],
      "-ch": ["bench", "catch", "lunch"],
      "-sh": ["fish", "brush"],
      "-th": ["bath", "teeth"]
    },
    {
      "unitNum" : [1,1,1,2,2,2,3,3,3,4,4,4,5,5,5],
      "button" : ["bl", "cl", "fl", "gr", "dr", "tr", "sn", "st", "sw", "ch", "sh", "th", "-ch", "-sh", "-th"],
      "bl": ["black", "block", "blanket"],
      "cl": ["clap", "clock"],
      "fl": ["flag", "fly"],
      "gr": ["green", "grass", "grape"],
      "dr": ["dress", "dragon"],
      "tr":["truck", "tree"],
      "sn": ["snake", "snack", "snore"],
      "st": ["stop", "stone"],
      "sw": ["swim", "swing"],
      "ch": ["chick", "chin", "cheese"],
      "sh": ["ship", "shell"],
      "th": ["think", "three"],
      "-ch": ["bench", "catch", "lunch"],
      "-sh": ["fish", "brush"],
      "-th": ["bath", "teeth"]
    },
    {
      "unitNum" : [7,7],
      "button" : ["-ai", "-ay"],
      "-ai": ["snail", "tail", "rain", "train"],
      "-ay": ["gray", "clay", "spray","crayon"]
    },
    {
      "unitNum" : [8,8],
      "button" : ["-ea", "-ee"],
      "-ea": ["seal", "leaf", "beach", "meat"],
      "-ee": ["bee", "sheep", "seed", "feet"]
    },
    {
      "unitNum" : [9,9],
      "button" : ["-oa", "-ow"],
      "-oa":  ["boat", "road", "goat", "coat"],
      "-ow": ["snow", "window", "blow", "yellow"]
    },
    {
      "unitNum" : [10,10],
      "button" : ["-ou", "-ow"],
      "-ou": ["cloud", "house", "blouse", "mouse"],
      "-ow": ["cow", "crown", "gown", "down"]
    },
    {
      "unitNum" : [11,11],
      "button" : ["-oi", "-oy"],
      "-oi": ["coin", "boil", "oil", "point"],
      "-oy": ["boy", "toy", "joy", "soybean"]
    },
    {
      "unitNum" : [7,7,8,8,9,9,10,10,11,11],
      "button" : ["ai", "ay", "ea", "ee", "oa", "ow", "ou", "-ow", "oi", "oy"],
      "ai": ["snail", "tail", "rain", "train"],
      "ay": ["gray", "clay", "spray","crayon"],
      "ea": ["seal", "leaf", "beach", "meat"],
      "ee": ["bee", "sheep", "seed", "feet"],
      "oa":  ["boat", "road", "goat", "coat"],
      "ow": ["snow", "window", "blow", "yellow"],
      "ou": ["cloud", "house", "blouse", "mouse"],
      "-ow": ["cow", "crown", "gown", "down"],
      "oi": ["coin", "boil", "oil", "point"],
      "oy": ["boy", "toy", "joy", "soybean"]
    }
  ],
];