import { Cookies } from 'react-cookie';

const cookies = new Cookies();

// refresh token cookie 저장
export const setRefreshToken = (refreshToken) => {
  const today = new Date();
  const expireDate = today.setDate(today.getDate() + 7);

  return cookies.set('refresh_token', refreshToken.refreshToKen, {
    sameSite : 'strict',
    path : "/",
    expires : new Date(expireDate)
  });
};

// cookie에 저장된 refresh token 값 가져오기
export const getCookieToken = () => {
  return cookies.get('refresh_token');
};

// refresh token cookie 삭제
export const removeCookieToken = () => {
  return cookies.remove('refresh_token', {sameSite : 'strict', path : "/"});
};