import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_book : 1,
  current_unit : 0,
  pop : false,
  type : 'unit',
  current_readers : 0,
};

const book = createSlice({
  name : "book",
  initialState,
  reducers : {
    resetBook(state, action) {
      state.current_unit = 0;
    },
    setCurrentBook(state, action) {
      state.current_book = action.payload;
    },
    setCurrentUnit(state, action) {
      state.current_unit = action.payload;
    },
    setPop(state, action) {
      state.pop = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setCurrentReaders(state, action) {
      state.current_readers = action.payload;
    },
  }
});

export const {
  resetBook,
  setCurrentBook,
  setCurrentUnit,
  setPop,
  setType,
  setCurrentReaders,
} = book.actions;

export default book;