import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from 'jwt-decode';

const initialState = {
  authenticated : false,
  access_token : null,
  expire_time : null,
};

const auth = createSlice({
  name : "auth",
  initialState,
  reducers : {
    resetAuth : () => initialState,
    setToken : (state, action) => {
      state.authenticated = true;
      state.access_token  = action.payload;
      state.expire_time   = jwt_decode(action.payload).exp * 1000;
    },
    deleteToken : (state) => {
      state.authenticated = false;
      state.access_token = null;
      state.expire_time = null;
    }
  }
});

export const {resetAuth, setToken, deleteToken} = auth.actions;

export default auth;