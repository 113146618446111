import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const SpanTag = styled.span`
  ${(props) => {
    switch (props.type) {
      case "on" :
        return css`
          background: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_on.png) center left /cover no-repeat;
          transition: 0.3s;
        `
      case "off":
        return css`
          background: url(https://content-cdn.chungchy.com/Phonics_Hero/images/common/audio_off.png) center right /cover no-repeat;
          transition: 0.3s;
        `
      case "unit":
        return css`
          color: #8f7500;
        `
      case "readers": case "auth":
        return css`
          color: #365cb5;
        `
      case "popup":
        return css`
          display: block;
          font-size: 2.1875vw;
        `
      case "great":
        return css`
          color: #40b51f;
        `
      case "good":
        return css`
          color: #ffb600;
        `
      case "again":
        return css`
          color: #dd121b;
        `
      default:
    }

  }}
  ${(props) =>((props.type === 'on' && (props.act ? 'width : 80%;' : 'width : 20%;')))};
  ${(props) =>((props.type === 'off' && (!props.act ? 'width : 80%;' : 'width : 20%;')))};
`;

export const Span = (props) => {
  return (
    <SpanTag type={props.type} act={props.act}>{props.children}</SpanTag>
  );
};

Span.propTypes = {
  type : PropTypes.string
};

Span.defaultProps = {
  type : 'on'
};