import { PropTypes } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentWord } from '../../../store/CardSlice';
import { Button } from '../../_atoms/Button';

export const FlashCardWord = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <>
      {props.card && props.card.button.map((btn, idx) => {
        if (props.start <= idx && idx <= props.end) {
          return <Button 
                  type="word" 
                  name={idx === 0 || idx % 3 === 0 ? "blue" : ((idx + 1) % 3 === 0 ? "pink" : "green")} 
                  act={btn === state.card.current_word ? true : false}
                  onClick={() => {
                    if (state.card.play) {
                      return false;
                    } 
                    
                    dispatch(setCurrentWord(btn))
                  }}
                >
                  {btn}
                </Button>
        }
      })}
    </>
  );
};

FlashCardWord.propTypes = {
  book : PropTypes.number,
  unit : PropTypes.number,
};

FlashCardWord.defaultProps = {
  book : 3,
  unit : 6
};