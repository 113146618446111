export const Readers = [
  [
    {
      "unit" : 4,
      "num" : 1,
      "sentence" : [
          "The alligator has a book.",
          "The cat has a car.",
          "It is the bear's birthday!",
          "They go to the bear's house.",
          "The house is an igloo!",
          "The bear likes his gifts.",
          "Happy birthday!"
      ],
      "path" : [
          "flashCard/mp3/readers/1-1.mp3",
          "flashCard/mp3/readers/1-2.mp3",
          "flashCard/mp3/readers/1-3.mp3",
          "flashCard/mp3/readers/1-4.mp3",
          "flashCard/mp3/readers/1-5.mp3",
          "flashCard/mp3/readers/1-6.mp3",
          "flashCard/mp3/readers/1-7.mp3"
      ]
    },
    {
      "unit" : 8,
      "num" : 2,
      "sentence" : [
          "The king has an old notebook.", 
          "But he cannot find the key.",
          "The king asks a question.", 
          "Where is the key?",
          "Everyone is quiet.",
          "Run and find it! the king says.",
          "The ostrich comes with a nail.", 
          "No, it is not the key.",
          "The rabbit comes with a pencil.", 
          "No, it is not the key!",
          "Look! The lion comes with a key!", 
          "Yes! It is the key for the notebook!"
      ],
      "path" : [
          "flashCard/mp3/readers/2-1.mp3",
          "flashCard/mp3/readers/2-2.mp3",
          "flashCard/mp3/readers/2-3.mp3",
          "flashCard/mp3/readers/2-4.mp3",
          "flashCard/mp3/readers/2-5.mp3",
          "flashCard/mp3/readers/2-6.mp3",
          "flashCard/mp3/readers/2-7.mp3",
          "flashCard/mp3/readers/2-8.mp3",
          "flashCard/mp3/readers/2-9.mp3",
          "flashCard/mp3/readers/2-10.mp3",
          "flashCard/mp3/readers/2-11.mp3",
          "flashCard/mp3/readers/2-12.mp3"
      ]
    },
    {
      "unit" : 12,
      "num" : 3,
      "sentence" : [
          "The sun is up in the sky. Let's play together!",
          "I'll hide! I'll find you!",
          "At the window? No. Only webs.",
          "In the box? No. Only a sock.",
          "Under the umbrella? Not here! Only a yacht.",
          "Found you! Hey! You are eating my yogurt!"
      ],
      "path" : [
          "flashCard/mp3/readers/3-1.mp3",
          "flashCard/mp3/readers/3-2.mp3",
          "flashCard/mp3/readers/3-3.mp3",
          "flashCard/mp3/readers/3-4.mp3",
          "flashCard/mp3/readers/3-5.mp3",
          "flashCard/mp3/readers/3-6.mp3"
      ]
    },
  ],
  [
    {
      "unit" : 4,
      "num" : 1,
      "sentence" : [
          "Ted is at the farm. \nThere are so many pets.",
          "“Hey, I have a nice red ball!” \nA dog digs.",
          "The dog doesn’t look at Ted.",
          "“Hey, I have a nice red ball!” \nA rat runs.",
          "The rat doesn’t look at Ted.",
          "“Hey, I have a nice red ball!” \nA fish swims.",
          "The fish doesn’t look at Ted.",
          "“Hey, I have a nice red ball!” \nA hen has a nap. ",
          "The hen doesn’t look at Ted.",
          "“Hey, I have a nice red bat!” \nDad comes.",
          "Dad looks at Ted."
      ],
      "path" : [
          "flashCard/mp3/readers/1-1.mp3",
          "flashCard/mp3/readers/1-2.mp3",
          "flashCard/mp3/readers/1-3.mp3",
          "flashCard/mp3/readers/1-4.mp3",
          "flashCard/mp3/readers/1-5.mp3",
          "flashCard/mp3/readers/1-6.mp3",
          "flashCard/mp3/readers/1-7.mp3",
          "flashCard/mp3/readers/1-8.mp3",
          "flashCard/mp3/readers/1-9.mp3",
          "flashCard/mp3/readers/1-10.mp3",
          "flashCard/mp3/readers/1-11.mp3"
      ]
    },
    {
      "unit" : 8,
      "num" : 2,
      "sentence" : [
          "What is that?", 
          "A fox runs out of the hut.",
          "The fox runs to the pop.", 
          "The fox sees a cave.",
          "The fox runs into the cave.",
          "The fox sees a box.",
          "The fox cuts the box. ", 
          "The fox sees a pot.",
          "The fox opens the pot.", 
          "The fox sees cakes.",
          "Jelly beans pop out!", 
          "“My friends! What a fun game!”"
      ],
      "path" : [
          "flashCard/mp3/readers/2-1.mp3",
          "flashCard/mp3/readers/2-2.mp3",
          "flashCard/mp3/readers/2-3.mp3",
          "flashCard/mp3/readers/2-4.mp3",
          "flashCard/mp3/readers/2-5.mp3",
          "flashCard/mp3/readers/2-6.mp3",
          "flashCard/mp3/readers/2-7.mp3",
          "flashCard/mp3/readers/2-8.mp3",
          "flashCard/mp3/readers/2-9.mp3",
          "flashCard/mp3/readers/2-10.mp3",
          "flashCard/mp3/readers/2-11.mp3",
          "flashCard/mp3/readers/2-12.mp3"
      ]
    },
    {
      "unit" : 12,
      "num" : 3,
      "sentence" : [
          "A man takes five cubes.",
          "The dragon wants them back.",
          "The man digs and digs. \nHe hides one cube in a hole. ",
          "But the dragon finds it.",
          "The man hikes and hikes. \nHe hides one cube on a dune. ",
          "But the dragon finds it.",
          "The man dives and dives. \nHe hides one cube in the sea.",
          "But the dragon finds it.",
          "The man rides and rides. \nHe hides one cube on a dome. ",
          "But the dragon finds it.",
          "The man takes the last one home. \nHe doesn’t hide it. ",
          "But the dragon can’t find it!"
      ],
      "path" : [
          "flashCard/mp3/readers/3-1.mp3",
          "flashCard/mp3/readers/3-2.mp3",
          "flashCard/mp3/readers/3-3.mp3",
          "flashCard/mp3/readers/3-4.mp3",
          "flashCard/mp3/readers/3-5.mp3",
          "flashCard/mp3/readers/3-6.mp3",
          "flashCard/mp3/readers/3-7.mp3",
          "flashCard/mp3/readers/3-8.mp3",
          "flashCard/mp3/readers/3-9.mp3",
          "flashCard/mp3/readers/3-10.mp3",
          "flashCard/mp3/readers/3-11.mp3",
          "flashCard/mp3/readers/3-12.mp3"
      ]
    },
  ],
  [
    {
      "unit" : 6,
      "num" : 1,
      "sentence" : [
          "Three chicks catch balls.",
          "“Good boys!” \nA dragon claps on the grass.",
          "The three chicks swim with fish.",
          "“Good boys!” \nThe dragon claps on the ship.",
          "The three chicks make lunch.",
          "“Good boys!” \nThe dragon claps in a truck.",
          "The three chicks swing.",
          "“Good boys!” \nThe dragon claps on the bench.",
          "The three chicks use brushes.",
          "“Good boys!” \nThe dragon claps in the bath.",
          "The three chicks all snore.",
          "“Oh, no!” the dragon says under the blanket."
      ],
      "path" : [
          "flashCard/mp3/readers/1-1.mp3",
          "flashCard/mp3/readers/1-2.mp3",
          "flashCard/mp3/readers/1-3.mp3",
          "flashCard/mp3/readers/1-4.mp3",
          "flashCard/mp3/readers/1-5.mp3",
          "flashCard/mp3/readers/1-6.mp3",
          "flashCard/mp3/readers/1-7.mp3",
          "flashCard/mp3/readers/1-8.mp3",
          "flashCard/mp3/readers/1-9.mp3",
          "flashCard/mp3/readers/1-10.mp3",
          "flashCard/mp3/readers/1-11.mp3",
          "flashCard/mp3/readers/1-12.mp3"
      ]
    },
    {
      "unit" : 12,
      "num" : 2,
      "sentence" : [
          "A seal is on the beach. \nThe seal sees a cow.", 
          "The cow is on the road. \nThe cow sees a mouse.",
          "The mouse is at the house. \nThe mouse sees a snail.", 
          "The snail is on the window. \nThe snail sees a boy.",
          "The boy sees the snail, too. \nHe points at the gray clouds.",
          "It is raining. The boy and the animals play. \nWhat joy!"
      ],
      "path" : [
          "flashCard/mp3/readers/2-1.mp3",
          "flashCard/mp3/readers/2-2.mp3",
          "flashCard/mp3/readers/2-3.mp3",
          "flashCard/mp3/readers/2-4.mp3",
          "flashCard/mp3/readers/2-5.mp3",
          "flashCard/mp3/readers/2-6.mp3"
      ]
    },
  ],
];