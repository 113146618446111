import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestBook } from '../../../api/Book';
import { resetBookAuth, setResult } from '../../../store/BookAuthSlice';
import { MainTemplate } from '../../_templates/MainTemplate';

const Menu  = [
  {
    src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book1.png',
    alt : 'Phonics Hero Book1',
    auth : false,
  },
  {
    src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book2.png',
    alt : 'Phonics Hero Book2',
    auth : false,
  },
  {
    src : 'https://content-cdn.chungchy.com/Phonics_Hero/images/common/main_book3.png',
    alt : 'Phonics Hero Book3',
    auth : false,
  },
];

export const Main = () => {
  const { access_token } = useSelector((state) => state.auth); // access token
  const {book, result} = useSelector((state) => state.bookAuth);

  const dispatch = useDispatch();

  const [menu, setMenu] = useState(Menu);

  useEffect(() => {
    const checkAuthBook = async (current_book) => {
      const response = await requestBook({current_book, access_token});

      if (response.status) {
        const book = response.json.info;

        if (book) {
          const copyMenu = {...menu};
          copyMenu[current_book - 1].auth = true;

          setMenu(copyMenu);
        }
      }
    };

    Menu.map((book, idx) => {
      checkAuthBook(idx + 1);
    });
  }, []);

  useEffect(() => {
    if (result) {
      const copyMenu = {...menu};
      copyMenu[book - 1].auth = true;

      setMenu(copyMenu);
      dispatch(setResult(false));
    } else {
      dispatch(resetBookAuth());
    }
  }, [result]);

  return (
    <MainTemplate menu={menu} />
  );
};