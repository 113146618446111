import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Ul = styled.ul`
  ${(props) => {
    switch (props.type) {
      case "audioUnit":
        return css`
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          box-shadow: -0.260417vw 0 0.520833vw 0 #000;
          overflow-y: unset;
          z-index: 1;
          width: 50%;
          height: 100%;
        `
      case "audioTrack":
        return css`
          position: absolute;
          top: 0;
          left: 50%;
          width: 50%;
          height: 100%;
          background-color: #f9f9f9;
          overflow-y: auto;
        `
      default:
    }
  }};
`;

export const List = (props) => {
  return (
    props.order ? 
      <ol>{props.children}</ol> :
      <Ul type={props.type} act={props.act}>{props.children}</Ul>
  );
};

List.propTypes = {
  order : PropTypes.bool,
  type : PropTypes.string,
  act : PropTypes.bool
};

List.defaultProps = {
  order : false,
  type : "audioUnit"
};