import { PropTypes } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setCurrentTrack, setCurrentUnit, setPlayTrack, setPlayUnit } from '../../../store/AudioSlice';
import { Anchor } from '../../_atoms/Anchor';
import { Item } from '../../_atoms/Item';
import { List } from '../../_atoms/List';

const AudioUnitList = styled.div`
  grid-row: span 3;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: -0.260417vw 0 0.520833vw 0 #000;
`;

export const AudioTrackWrap = (props) => {
  const state = useSelector((state) => state.audio);
  const dispatch = useDispatch();

  return (
    <AudioUnitList>
      <List type="audioUnit" order={false}>
      {props.unit && props.unit.map((item, idx) => {
        return (
          <Item type="audioUnit">
            <Anchor 
              type="audioUnit" 
              act={idx === state.current_unit ? true : false}
              onClick={() => dispatch(setCurrentUnit(idx))}
            >
              {(idx === 12) ? 'Readers' : (idx < 9 ? 'Unit 0' + (idx + 1) : 'Unit ' + (idx + 1))}
            </Anchor>
          </Item>
        )
      })}
      </List>

      <List type="audioTrack" order={false}>
        {props.track && props.track.map((item, idx) => {
          return (
            <Item type="audioTrack">
              <Anchor 
                type="audioTrack" 
                act={state.current_unit === state.play_unit && idx === state.current_track ? true : false}
                onClick={() => {
                  dispatch(setCurrentTrack(idx)); 
                  dispatch(setPlayUnit(state.current_unit));
                  dispatch(setPlayTrack(state.current_track));
                }}
              >
                Track {item < 10 ? '0' + item : item}
              </Anchor>
            </Item>
          )
        })}
      </List>
    </AudioUnitList>
  );
};

AudioTrackWrap.propsTypes = {
  list : PropTypes.array,
  track : PropTypes.array
};

AudioTrackWrap.defaultTypes = {
  list : [
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    [12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    [22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    [32, 33, 34],
    [35, 36, 37, 38, 39, 40, 41, 42, 43, 44],
    [45, 46, 47, 48, 49, 50, 51, 52, 53, 54],
    [55, 56, 57, 58, 59, 60, 61, 62, 63, 64],
    [65, 66, 67],
    [68, 69, 70, 71, 72, 73, 74, 75, 76, 77],
    [78, 79, 80, 81, 82, 83, 84, 85, 86, 87],
    [88, 89, 90, 91, 92, 93, 94, 95],
    [96, 97, 98],
    [99, 100, 101],
  ],
  track : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
};