import { PropTypes } from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setType } from "../../../store/VideoSlice";
import { VideoTemplate } from "../../_templates/VideoTemplate";

export const Video = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setType(props.type));
  }, []);

  return (
    <VideoTemplate />
  );
};

Video.propTypes = {
  type : PropTypes.string,
};

Video.defaultProps = {
  type : 'chant'
};