import { Fragment } from "react";
import Frame, { FrameContextConsumer } from "react-frame-component";
import { StyleSheetManager } from "styled-components";

export const Iframe = ({children}) => {
  return (
    <Frame title="Phonics Hero" style={{width : "100%", height : "100%", border : 0}}>
      <FrameContextConsumer>
        {FrameContext => (
          <StyleSheetManager target={FrameContext.document.head}>
            <Fragment>
              {children}
            </Fragment>
          </StyleSheetManager>
        )}
      </FrameContextConsumer>
    </Frame>
  );
};