import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Li = styled.li`
  ${(props) => {
    switch (props.type) {
      case "audioUnit": case "audioTrack":
        return css`
          border-bottom: 0.052083vw solid #e5e5e5;
        `
      default:
    }
  }}
`;

export const Item = (props) => {
  return (
    <Li type={props.type}>{props.children}</Li>
  );
};

Item.propTypes = {
  type : PropTypes.string,
};

Item.defaultProps = {
  type : 'audioUnit'
};