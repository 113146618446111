import { Fragment } from "react";
import { Route, Routes } from "react-router";
import { GlobalStyle } from "./asset/styles/GlobalStyle";
import { IframeGlobalStyle } from './asset/styles/IframeGlobalStyle';
import { Iframe } from "./components/_atoms/Iframe";
import { ButtonSound } from "./components/_organisms/ButtonSound";
import { Sound } from "./components/_organisms/Sound";
import { Audio } from "./components/pages/Audio";
import { Auth } from "./components/pages/Auth";
import { Book } from "./components/pages/Book";
import { Contents } from "./components/pages/Contents";
import { FlashCard } from "./components/pages/FlashCard";
import { Main } from "./components/pages/Main";
import { Readers } from "./components/pages/Readers";
import { Speaking } from "./components/pages/Speaking";
import { SpeakingIntro } from "./components/pages/SpeakingIntro";
import { Video } from "./components/pages/Video";
import { AuthBookRoute } from "./routes/AuthBookRoute";
import { PrivateRoute } from "./routes/PrivateRoute";

function App() {
  return (
    <Fragment>
      <GlobalStyle />
      <Iframe>
        <IframeGlobalStyle />
        <Routes>
          <Route path="/auth/:access/:refresh" element={<Auth/>}/>

          <Route element={<PrivateRoute />}>
            <Route element={<Sound />}>
              <Route path="/" element={<Main />} />
              <Route element={<AuthBookRoute />}>
                <Route path="/book" element={<Book />} />
                <Route path="/book/contents" element={<Contents />} />
                <Route path="/book/readers" element={<Readers />} />
              </Route>
            </Route>
            <Route element={<ButtonSound />}>
              <Route element={<AuthBookRoute />}>
                <Route path="/book/audio" element={<Audio />} />
                <Route path="/book/contents/flashCard/:type" element={<FlashCard />} />
                <Route path="/book/contents/chant" element={<Video type="chant" />} />
                <Route path="/book/contents/story" element={<Video type="story" />} />
                <Route path="/book/contents/readers" element={<Video type="readers" />} />
                <Route path="/book/readers/story" element={<Video type="stories" />} />
                <Route path="/book/readers/speaking" element={<SpeakingIntro />} />
                <Route path="/book/readers/speaking/start" element={<Speaking />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Iframe>
    </Fragment>
  );
}

export default App;
