import { Outlet, useLocation } from "react-router-dom";
import { CheckBook } from "../auth/CheckBook";

export const AuthBookRoute = () => {
  const location = useLocation();
  const { isAuth } = CheckBook(location.key);

  if (isAuth === 'Failed') {
    alert('This book is not registered.\nPlease check the code of the book.');
    window.location.href="/";
  }

  return <Outlet />;
};
